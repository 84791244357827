import React from 'react';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import landingState from 'constants/landingState';
import { AnyObject } from 'types/Common';
import getPath from 'helpers/path';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import Messages from 'constants/rpcTypes';

interface ConnectedProps {
  user: AnyObject;
}

type Props = ConnectedProps & WithPermissions;

const RootContainer: React.VFC<Props> = ({ user, isEnabled }) => {
  switch (user.landingState) {
    case landingState.maf:
      return <Redirect to={getPath('/maf')} />;
    case landingState.demoStage:
      if (isEnabled(Messages.DemoStage_Project)) {
        return <Redirect to={getPath('/get-started')} />;
      } else if (isEnabled(Messages.Payments_List)) {
        return <Redirect to={getPath('/payments')} />;
      }
      return <Redirect to={getPath('/profile')} />;
    case landingState.default:
      if (isEnabled(Messages.GetStarted_View)) {
        return <Redirect to={getPath('/get-started')} />;
      } else if (isEnabled(Messages.Payments_List)) {
        return <Redirect to={getPath('/payments')} />;
      }
      return <Redirect to={getPath('/profile')} />;
    default:
      return <Redirect to={getPath('/profile')} />;
  }
};

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
});

export default connect(mapStateToProps)(addPermissions(RootContainer));
