import React, { PureComponent } from 'react';
import { AutoSizer, InfiniteLoader, List } from 'react-virtualized';
import Tooltip from 'react-tooltip';
import { debounce } from 'lodash-es';
import { Width } from 'types/ItemConfiguration';
import CustomScrollbar from 'components/ui/customScrollbar';
import './smartList.scss';

interface Props {
  list: any[];
  headerHeight: number;
  rowHeight: number;
  totalCount: number;
  fixedColumnWidth?: number;
  contentId?: string;
  getRef?: any;
  headerRender?: React.ReactNode;
  hideHorizontal?: boolean;
  initialScroll?: any;
  noContent?: React.ReactNode;
  thresholdRows?: number;
  tooltip?: boolean;
  rowRenderer: (data, index: number) => React.ReactNode;
  onReady: () => void;
  getCustomContentScrollWidth: () => number;
  loadNextPage?: (offset: number) => Promise<any>;
  onScroll?: (element: Element) => void;
  onScrollStop?: (element: Element) => void;
  onUpdate?: (element: Element) => void;
  setWidth?: (width?: Width, cb?: () => void) => void;
}

interface State {
  isLoading: boolean;
  scrollLeft: number;
  scrollTop: number;
}

class SmartList extends PureComponent<Props, State> {
  List;
  scrollbarRef;
  headerHolderRef: HTMLElement | null = null;
  resizeTimeout: NodeJS.Timeout | undefined = undefined;

  rebuildTooltip = debounce(() => Tooltip.rebuild(), 200, {
    leading: false,
    trailing: true,
  });

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      scrollLeft: 0,
      scrollTop: 0,
    };
  }

  componentDidMount() {
    const { scrollbarRef } = this;
    const { initialScroll, onReady } = this.props;

    if (initialScroll) {
      const { scrollTop, scrollLeft } = initialScroll;

      if (scrollbarRef) {
        setTimeout(() => {
          scrollbarRef.scrollTo(scrollLeft, scrollTop);
          if (
            scrollbarRef.scrollerElement &&
            scrollbarRef.scrollerElement.scrollTop !== scrollTop
          ) {
            setTimeout(() => {
              scrollbarRef.scrollTo(scrollLeft, scrollTop);
              onReady();
            }, 200);
          } else {
            onReady();
          }
        });
      } else {
        onReady();
      }
    } else {
      onReady();
    }
    this.props.tooltip && this.rebuildTooltip();
    this.attachEvents();
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { list, tooltip } = this.props;

    if (list.length !== prevProps.list.length && this.scrollbarRef) {
      this.handleScroll(this.scrollbarRef); //correct header horizontal position
    }

    tooltip && this.rebuildTooltip();
  }

  componentWillUnmount() {
    this.handleHeaderScrollFunction(false);
    this.detachEvents();
  }

  renderHeader = () => {
    const { list, headerRender, headerHeight } = this.props;

    if (list.length) {
      return headerRender;
    }

    return (
      <div className='header-holder__empty-content'>
        <CustomScrollbar customHeight={headerHeight} hideVertical>
          {headerRender}
        </CustomScrollbar>
      </div>
    );
  };

  rowRenderer = ({ index, key, style }) => {
    const { rowRenderer, list } = this.props;
    const row = list[index];

    return (
      <div key={key} style={style} className='smart-list__row'>
        {rowRenderer(row, index)}
      </div>
    );
  };

  loadNextPage = ({ startIndex }) => {
    const { loadNextPage } = this.props;

    if (typeof loadNextPage === 'function') {
      if (this.state.isLoading) return false;
      this.setState({
        isLoading: true,
      });

      loadNextPage(startIndex).then(() => {
        this.setState({
          isLoading: false,
        });
      });
    }
  };

  handleScroll = (values) => {
    const { onScroll } = this.props;
    const { scrollTop, scrollLeft } = values;
    const { Grid: grid } = this.List;

    this.setState({ scrollLeft, scrollTop });
    grid.handleScrollEvent({ scrollTop, scrollLeft });
    onScroll && onScroll(values);
  };

  handleUpdate = (values) => {
    const { onUpdate } = this.props;

    onUpdate && onUpdate(values);
  };

  handleStop = (values) => {
    const { scrollbarRef } = this;
    const { onScrollStop } = this.props;

    if (scrollbarRef) {
      onScrollStop && onScrollStop(values);
    }
  };

  handleHeaderScrollFunction = (isToAdd) => {
    if (this.headerHolderRef) {
      if (isToAdd) {
        this.headerHolderRef.addEventListener('wheel', this.handleWheelScroll, {
          passive: true,
        });
      } else {
        this.headerHolderRef.removeEventListener(
          'wheel',
          this.handleWheelScroll
        );
      }
    }
  };

  handleWheelScroll = (e) => {
    let { deltaY } = e;
    const { scrollTop, scrollLeft } = this.state;
    const { rowHeight } = this.props;

    deltaY =
      Math.abs(deltaY) > rowHeight ? deltaY : Math.sign(deltaY) * rowHeight;

    this.scrollbarRef?.scrollTo(scrollLeft, scrollTop + deltaY);
  };

  onResize = () => {
    const { setWidth } = this.props;
    this.rebuildTooltip();

    setTimeout(() => {
      if (!this.scrollbarRef) {
        return;
      }
      const { scrollWidth, clientWidth } = this.scrollbarRef;
      const contentScrollable = scrollWidth > clientWidth;

      if (setWidth && !contentScrollable) {
        clearTimeout(this.resizeTimeout);
        this.resizeTimeout = setTimeout(() => {
          setWidth(undefined);
        }, 500);
      }
    }, 0);
  };

  attachEvents = () => {
    window.addEventListener('resize', this.onResize);
  };

  detachEvents = () => {
    window.removeEventListener('resize', this.onResize);
  };

  render() {
    const {
      list,
      rowHeight,
      headerHeight,
      totalCount,
      getRef,
      noContent,
      contentId,
      fixedColumnWidth,
      thresholdRows = 2,
      hideHorizontal = false,
      getCustomContentScrollWidth,
    } = this.props;
    const { scrollLeft } = this.state;

    const listStyle: React.CSSProperties = {
      overflowX: undefined,
      overflowY: undefined,
      top: headerHeight,
    };

    return (
      <div className='smart-list'>
        <div
          ref={(ref) => {
            this.headerHolderRef = ref;
            this.handleHeaderScrollFunction(true);
          }}
          className='header-holder'
          style={{ left: 0 - scrollLeft }}>
          {this.renderHeader()}
        </div>
        {list.length > 0 ? (
          <AutoSizer>
            {({ width, height }) => {
              const scrollWidth = fixedColumnWidth
                ? getCustomContentScrollWidth()
                : width;

              return (
                <CustomScrollbar
                  customWidth={width}
                  customHeight={height}
                  hideHorizontal={hideHorizontal}
                  fixedColumnWidth={fixedColumnWidth}
                  getRef={(el: HTMLElement) => {
                    this.scrollbarRef = el;
                    getRef && getRef(el);
                  }}
                  onScroll={this.handleScroll}
                  onUpdate={this.handleUpdate}
                  onScrollStop={this.handleStop}>
                  <div id={contentId} className='smart-list__content'>
                    <InfiniteLoader
                      isRowLoaded={({ index }) => !!list[index]}
                      loadMoreRows={this.loadNextPage}
                      threshold={thresholdRows}
                      rowCount={totalCount}>
                      {({ onRowsRendered, registerChild }) => {
                        return (
                          <List
                            height={height - headerHeight}
                            onRowsRendered={onRowsRendered}
                            ref={(refList) => {
                              this.List = refList;
                              registerChild(refList);
                            }}
                            rowCount={list.length}
                            rowHeight={rowHeight}
                            style={listStyle}
                            rowRenderer={this.rowRenderer}
                            width={scrollWidth}
                          />
                        );
                      }}
                    </InfiniteLoader>
                  </div>
                </CustomScrollbar>
              );
            }}
          </AutoSizer>
        ) : (
          noContent
        )}
      </div>
    );
  }
}

export default SmartList;
