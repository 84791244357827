import CONFIG from 'config';

/**
 * Класс работы с окружением
 */
class Env {
  /**
   * Приложение запущено режиме разработки
   */
  static isDevelopment() {
    return import.meta.env.DEV;
  }

  /**
   * Приложение запущено в прод режиме
   */
  static isProduction() {
    return import.meta.env.PROD;
  }

  /**
   * Пока добавим сюда, когда появится больше различий для дев и прод режимов,
   * можно разделить на несколько конфигов config.dev.ts, config.prod.ts,
   * а для работы с ними создать отдельный класс, вроде ConfigManager
   */
  static getPublicPath() {
    return Env.isProduction() ? CONFIG.PUBLIC_PATH : '';
  }

  static getApiUrl() {
    return process.env.REACT_APP_API_URL || '';
  }

  static getWsUrl() {
    const { location } = window;
    return process.env.REACT_APP_WS_URL || `wss://${location.host}/ws`;
  }

  static getIconHost() {
    return process.env.REACT_APP_ICON_HOST || ``;
  }

  static getReleaseTag() {
    return import.meta.env.VITE_BUILD_TAG || '';
  }

  static getDwhNMocked() {
    return process.env.REACT_APP_DWH_MOCKED || '';
  }

  // NodeJS ver named as currentBuilder for security reasons, itsec ask to name like this
  static getCurrentBuilder() {
    return `${import.meta.env.VITE_BUILD_NODE_VERSION || ''} ${
      import.meta.env.VITE_BUILD_NPM_VERSION || ''
    }`;
  }

  static getAppVersion() {
    return `${import.meta.env.VITE_BUILD_TAG || ''} ${
      import.meta.env.VITE_BUILD_COMMIT || ''
    }`;
  }

  static getIsSentryEnabled() {
    return import.meta.env.VITE_SENTRY_ENABLE === 'true';
  }
}

export default Env;
