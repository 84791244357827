import React from 'react';
import { useTranslation } from 'hooks';
import Loader from 'components/ui/loader';
import Animation from 'components/ui/animation';
import NoContent from 'components/ui/noContent';
import { MafForm } from 'components/maf/components/form';
import Grid from 'components/grid';
import LeavingPagePrompt from 'components/maf/components/modals/leavingPagePrompt';
import { MafHelpers } from 'components/maf/helpers';
import { MafContainerProps } from 'components/maf/types/props';
import { MafSteps } from './components/MafSteps';
import { MafHeader } from './components/MafHeader';
import { MafActions } from './components/MafActions';
import { MafOverview } from './components/MafOverview';
import { MafReviewInformer } from './components/MafReviewInformer';
import { PagesTabManager } from 'components/maf/components/PagesTabManager';
import ModalTabsManager from 'components/maf/components/ModalTabsManager';

import 'components/maf/maf.scss';

interface Props extends MafContainerProps {
  useModernInputs?: boolean;
  wasCommited: boolean;
  goToMafList: () => void;
}

const BusinessDocumentsFormView: React.FC<Props> = ({
  mafId,
  data,
  wasCommited,
  fetchError,
  isMafFetching,
  modalTab,
  isEdited,
  selectedEntity,
  isUpdating,
  isCommitting,
  validationErrors,
  currentSteps,
  canCommit,
  fieldState,
  currentStep,
  openedModalTabs,
  legalEntityId,
  boarding_status,
  isFormLocked,
  goToMafList,
  confirm,
  forseMarkStepAsFailed,
  getTabByName,
  onUpdate,
  onChangeStep,
  onCreateFormByTemplate,
  onDeleteForm,
  onChangeField,
  onDeleteAllTabs,
  onOpenModalTab,
  onCloseModalTab,
  onDeleteTab,
  onCommit,
  onBlur,
  onBackAndCancelStep,
}) => {
  const getTranslate = useTranslation();

  const handleUpdate = async () => {
    if (
      !modalTab ||
      !MafHelpers.isSignatorySection(currentSteps[currentStep])
    ) {
      return onUpdate();
    }

    const verifyCheckbox = modalTab.tabs[0]?.fields.find(
      (field) => field.name === 'UsrVerifyAuthorisedSignatoryDocuments'
    );

    if (!verifyCheckbox) {
      return onUpdate();
    }

    const isVerifyCheckboxDisabled = fieldState.isDisabled(
      modalTab.tabs[0],
      verifyCheckbox
    );

    if (isVerifyCheckboxDisabled) {
      return onUpdate();
    }

    const didUserCheckVerify = verifyCheckbox?.value === 'True';

    if (!didUserCheckVerify) {
      confirm({
        title: 'You Have Not Confirmed the Verification',
        text: 'Do you want to pass it now?',
        buttonYes: 'Verify Now',
        buttonNo: 'Verify Later',
      }).then((isOk) => {
        if (!isOk) {
          onUpdate();
        } else {
          onChangeField(
            modalTab.tabs[0]?.name,
            'UsrVerifyAuthorisedSignatoryDocuments',
            {
              value: 'True',
            }
          );
          const UsrUseAsSignatoryChkBx = modalTab.tabs[0]?.fields.find(
            (field) => field.name === 'UsrUseAsSignatory'
          );

          if (
            UsrUseAsSignatoryChkBx &&
            fieldState.isVisible(modalTab.tabs[0], UsrUseAsSignatoryChkBx)
          ) {
            onChangeField(modalTab.tabs[0]?.name, 'UsrUseAsSignatory', {
              value: 'True',
            });
          }
        }
      });
    } else {
      confirm({
        title: getTranslate('modals.businessDocs.signatorySending.title'),
        text: getTranslate('modals.businessDocs.signatorySending.text'),
        buttonYes: getTranslate('common.send.button'),
        buttonNo: getTranslate('common.cancel.button'),
      }).then((isOk) => {
        if (isOk) {
          onUpdate();
        }
      });
    }
  };

  if (isMafFetching || !data) {
    return <Loader />;
  }

  if (wasCommited) {
    return <MafReviewInformer onButtonClick={goToMafList} />;
  }

  if (fetchError) {
    return (
      <div className='page-maf'>
        <NoContent text={getTranslate('add.entities.info.text')} />
      </div>
    );
  }

  const currentForm = currentSteps[currentStep];
  const isAllowMultipleInputsPerRows = boarding_status === 'daf_fill';

  return (
    <div className='page-maf'>
      <Animation>
        <div className='grid-row'>
          <div className='grid-row-item grid-column-12'>
            <div className='maf-content'>
              <LeavingPagePrompt isEdited={isEdited} />
              <div className='maf-content__main'>
                <div className='maf-content__inner'>
                  <div className='grid-row grid-column-12'>
                    <Grid
                      direction='column'
                      className='maf-content__left-column'>
                      <MafHeader selectedEntity={selectedEntity} />
                      <MafSteps
                        currentStep={currentStep}
                        validationErrors={validationErrors}
                        tabs={currentSteps}
                        form={currentForm}
                        onChangeStep={onChangeStep}
                        isFormLocked={isFormLocked}
                      />
                    </Grid>

                    <div className='maf-form-wrapper'>
                      <ModalTabsManager
                        tabs={openedModalTabs}
                        currentStep={currentForm}>
                        {(formData) => (
                          <PagesTabManager
                            tab={formData}
                            fieldState={fieldState}>
                            {({
                              formData,
                              isPageTabMode,
                              isCurrrentPageFirst,
                              isCurrentPageLast,
                            }) => (
                              <>
                                {currentStep !== currentSteps.length ||
                                formData ? (
                                  <MafForm
                                    isPageTabMode={isPageTabMode}
                                    fileTypes='image/jpg,image/jpeg,image/png,.pdf,.zip,.rar,.doc,.docx'
                                    key={`maf-form-tab-${formData?.name}`}
                                    onCreateFormByTemplate={
                                      onCreateFormByTemplate
                                    }
                                    currentFormName={currentForm?.caption}
                                    onDeleteTab={onDeleteTab}
                                    allowMultipleInputsPerRows={
                                      isAllowMultipleInputsPerRows
                                    }
                                    currentSteps={currentSteps}
                                    currentStep={currentStep}
                                    validationErrors={validationErrors}
                                    onChangeField={onChangeField}
                                    onDeleteAllTabs={onDeleteAllTabs}
                                    getTabByName={getTabByName}
                                    onDeleteForm={onDeleteForm}
                                    fieldState={fieldState}
                                    onOpenModalTab={onOpenModalTab}
                                    ignoreDuplicateHeaders={false}
                                    onCloseModalTab={onCloseModalTab}
                                    formData={formData}
                                    isInnerTabOpen={Boolean(
                                      openedModalTabs.length
                                    )}
                                    data={data}
                                    mafId={mafId}
                                    legalEntityId={legalEntityId}
                                    onBlur={onBlur}
                                    forseMarkStepAsFailed={
                                      forseMarkStepAsFailed
                                    }
                                    openedModalTabs={openedModalTabs}
                                  />
                                ) : (
                                  <MafOverview
                                    validationErrors={validationErrors}
                                    currentSteps={currentSteps}
                                    onChangeStep={onChangeStep}
                                    isVisible={fieldState.isVisible}
                                    isFormLocked={isFormLocked}
                                  />
                                )}
                                <MafActions
                                  isOverviewStep={
                                    currentSteps.length === currentStep
                                  }
                                  isPageTabMode={Boolean(isPageTabMode)}
                                  isFirstStep={currentStep === 0}
                                  isModalMode={Boolean(openedModalTabs.length)}
                                  canUpdate={!isFormLocked}
                                  canCommit={canCommit}
                                  onCloseModalTab={onCloseModalTab}
                                  onBackAndCancelStep={onBackAndCancelStep}
                                  onCommit={onCommit}
                                  onUpdate={handleUpdate}
                                  isCurrentPageFirst={isCurrrentPageFirst}
                                  isCurrentPageLast={isCurrentPageLast}
                                />
                              </>
                            )}
                          </PagesTabManager>
                        )}
                      </ModalTabsManager>
                    </div>
                  </div>
                </div>
                {(isUpdating || isCommitting) && <Loader />}
              </div>
            </div>
          </div>
        </div>
      </Animation>
    </div>
  );
};

export { BusinessDocumentsFormView };
