import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';

import { resetAll } from 'actions/app';
import { resetUserErrors } from 'actions/user';
import api from 'api/user';
import PageAuthWrapper from '../components/PageAuthWrapper';
import LoginForm from './LoginForm';
import path from 'helpers/path';
import LocalStorage from 'helpers/LocalStorage';
import { StoreProps } from 'store';
import { WithRouterProps } from 'decorators/withRouter';

interface ConnectedProps {
  user: any;
  settings: { isSignUpAvailable: boolean; wl: string };
}

type Props = ConnectedProps & StoreProps & WithRouterProps;

interface State {
  email: string;
  password: string;
  isLoading: boolean;
}

class LoginPage extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      email: '',
      password: '',
      isLoading: false,
    };

    props.dispatch(resetUserErrors());
  }

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { dispatch, history } = this.props;
    const { state } = history.location;

    // state.reset нужен если при ошибке, пришедшей от сервера мы должны разлогинивать
    if ((prevProps.user && !this.props.user) || state?.reset) {
      if (state?.reset && this.props.user?.email) {
        LocalStorage.set('autoLogoutEmail', this.props.user.email);
      }
      dispatch(resetAll());
      history.replace({
        state: { ...state, reset: false },
      });
    }
  }

  render() {
    const { email, password, isLoading } = this.state;
    const { user, settings } = this.props;
    const { isSignUpAvailable, wl } = settings;
    const signUpLink = isSignUpAvailable ? `https://${wl}.com/sign-up/` : '';

    return (
      <PageAuthWrapper title='auth.login.header'>
        <LoginForm
          signUpLink={signUpLink}
          validation={user && user.validationErrors}
          email={email}
          password={password}
          isLoading={isLoading}
          onChange={this.changeFields}
          onLogin={() => this.login()}
          goToResetPassword={this.goToResetPassword}
        />
      </PageAuthWrapper>
    );
  }

  changeFields = (key, value: string) => {
    this.setState({
      [key]: value,
    } as { email: string; password: string });
  };

  goToResetPassword = () => {
    const { history } = this.props;
    history.push(path('/reset-password'));
  };

  async login() {
    this.setState({ isLoading: true });
    try {
      const { email, password } = this.state;
      await api.login({
        email: email.trim(),
        password,
      });
    } finally {
      this.setState({ isLoading: false });
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  user: state.user,
  settings: state.settings,
});

export default withRouter(connect(mapStateToProps)(LoginPage));
