import React, { CSSProperties, PureComponent, ReactNode } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import getConfigurationByName from 'selectors/getConfigurationByName';
import { StoreProps } from 'store';

import ResizeBar from 'components/ui/table/resizeBar/ResizeBar';
import Icon from 'components/ui/icon';
import Lock from './components/lock';
import SortingCell from './components/sortingCell';

import Utils from 'helpers/Utils';
import ItemConfiguration from 'types/ItemConfiguration';
import { AnyObject } from 'types/Common';
import { MIN_COLUMN_WIDTH, TABLE_HEADER_HEIGHT } from 'constants/ui';

interface OwnProps {
  tableName: string;
  outerSort?: any;
  tableHeight: CSSProperties['height'];
  width?: { [id: string]: number };
  setHeaderRef: (HTMLDivElement) => void;
  resizableColumns?: boolean;
  forbidResizeFor?: string[];
  updateWidth?: ((id: string, width: number) => void) | undefined;
  minColumnsWidth?: AnyObject;
  onSort?: ({ field, order }: { field: string; order: string }) => void;
}

interface ConnectedProps {
  configuration: ItemConfiguration[];
  sort: {
    field: string;
    order: 'desc' | 'asc';
  };
}

type Props = OwnProps & ConnectedProps & StoreProps & IntlProps;

interface State {
  isResizing: boolean;
}

class TableHeaders extends PureComponent<Props, State> {
  state = { isResizing: false };
  static defaultProps: Readonly<Partial<OwnProps>> = {
    width: {},
    minColumnsWidth: {},
    resizableColumns: true,
  };

  render() {
    const {
      configuration,
      tableName,
      getTranslate,
      tableHeight,
      width,
      minColumnsWidth,
      resizableColumns,
      forbidResizeFor,
      updateWidth,
      setHeaderRef,
      onSort,
      outerSort,
      sort,
    } = this.props;

    const { isResizing } = this.state;
    return (
      <div
        ref={setHeaderRef}
        id={`${tableName}-list-header`}
        className={classNames('ui-table__header-list', {
          'ui-table__header-list__resizing': isResizing,
        })}>
        {/* eslint-disable-next-line complexity */}
        {configuration.map((config) => {
          const { id } = config;
          const isColumnResizable =
            resizableColumns && !forbidResizeFor?.includes(id);

          const resizeStyles = isColumnResizable
            ? { width: width![id], maxWidth: width![id] }
            : {};

          const minWidth = minColumnsWidth![id] || MIN_COLUMN_WIDTH;
          let content: ReactNode = getTranslate(config.localId);

          const sortConfig = outerSort || sort;

          // TODO вынести в отдельный конфиг где каждой таблицы будут перечислены колонки, по которым можно сортировать
          if (
            tableName !== 'users' &&
            tableName !== 'batch' &&
            tableName !== 'invoices' &&
            (id === 'creationDate' ||
              (id === 'updatedDate' && tableName !== 'subscriptionPayments') ||
              id === 'createdAt' ||
              id === 'updatedAt' ||
              id === 'scheduledAt' ||
              id === 'processingDate' ||
              config.sort)
          ) {
            content = (
              <SortingCell
                onSort={onSort}
                sort={sortConfig}
                tableName={tableName}
                id={id}>
                {content}
              </SortingCell>
            );
          } else if (id === 'controls' || id === 'download') {
            content = (
              <div className='ui-controls-block'>
                <Icon name='im-Tick' size={10} />
              </div>
            );
          }

          if (Utils.hasProp(config, 'fixed') && config.id !== 'errors') {
            content = (
              <Lock
                content={content}
                tableName={tableName}
                isFixed={Boolean(config['fixed'])}
              />
            );
          }

          return (
            <span
              id={id}
              style={resizeStyles}
              className={classNames(
                `ui-table__header-item ui-table__column ui-table__column_${id}`,
                { 'ui-table__column_fixed': config.fixed },
                { 'resizable-column': isColumnResizable }
              )}
              key={id}>
              {content}
              {isColumnResizable && (
                <ResizeBar
                  setIsResizing={this.setIsResizing}
                  width={width![id]}
                  updateWidth={updateWidth}
                  id={id}
                  minWidth={minWidth}
                  height={tableHeight || TABLE_HEADER_HEIGHT}
                />
              )}
            </span>
          );
        })}
      </div>
    );
  }

  setIsResizing = (isResizing) => this.setState({ isResizing });
}

const mapStateToProps = (state, ownProps: OwnProps): ConnectedProps => ({
  configuration: getConfigurationByName(state, ownProps.tableName),
  sort: ownProps.outerSort || state.data[ownProps.tableName]?.sort,
});

export default connect(mapStateToProps)(addTranslation(TableHeaders));
