import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  uploadFile,
  getBatchPreview,
  confirmBatch,
  removeItemFromBatch,
} from 'api/remittance';
import { openModal } from 'actions/modal';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addListeners } from 'decorators/addListeners';
import MassPayment from 'components/formFields/components/massPayment/MassPayment';
import { MassPaymentGeneralColumn } from 'components/formFields/components/massPayment/components/MassPaymentGeneralColumn';
import { MassPaymentAdditionalColumn } from 'components/formFields/components/massPayment/components/MassPaymentAdditionalColumn';
import { MassPaymentFooter } from 'components/formFields/components/massPayment/components/MassPaymentFooter';
import BatchPreview from 'components/formFields/components/massPayment/components/batchPreview';
import SubmittedForm from 'components/formFields/components/submittedForm';
import path from 'helpers/path';
import urlsMap from 'constants/urlsMap';
import Messages from 'constants/rpcTypes';
import BatchFile from 'types/BatchFile';
import { StoreProps } from 'store';

const POLLING_INTERVAL = 10000;

enum BATCH_STATUSES {
  awaiting_parse = 'awaiting_parse',
  awaiting_confirm = 'awaiting_confirm',
  awaiting_verification = 'awaiting_verification',
  queued = 'queued',
  processing = 'processing',
  completed = 'completed',
  error = 'error',
}

interface Props extends StoreProps, IntlProps {}

interface State {
  file: null | File;
  isFilePreview: boolean;
  isBatchPreview: boolean;
  isFileLoading: boolean;
  isLoading: boolean;
  error: string;
  status: BATCH_STATUSES | string;
  batch: null | BatchFile;
  idsToCheck: Record<number, boolean> | null;
  isDeleting: boolean;
}

@addListeners([
  Messages.Remittance_PaymentBatchPreview,
  Messages.Remittance_PaymentBatchConfirm,
  Messages.Confirm_Psd2,
  Messages.Remittance_PaymentBatchRemoveItem,
])
class RemittancePaymentMass extends Component<Props, State> {
  pollingTimerId;
  remittancePaymentBatchId;

  constructor(props) {
    super(props);
    this.state = {
      file: null,
      batch: null,
      isLoading: false,
      isFileLoading: false,
      isFilePreview: false,
      isBatchPreview: false,
      error: '',
      status: '',
      idsToCheck: null,
      isDeleting: false,
    };
  }

  render() {
    return (
      <MassPayment
        id='remittance'
        isLoading={this.state.isLoading}
        isSuccess={!!this.state.status}
        isFilePreview={this.state.isBatchPreview}
        batch={this.state.batch}
        previewText='remittance.addPayout.mass.refund.infoText'
        renderGeneralColumn={this.renderGeneralColumn}
        renderAdditionalColumn={this.renderAdditionalColumn}
        renderFooter={this.renderFooter}
        renderBatch={this.renderBatchPreview}
        renderSubmitted={this.renderSubmitted}
      />
    );
  }

  componentWillUnmount(): void {
    this.stopPolling();
  }

  renderGeneralColumn = () => {
    const { getTranslate } = this.props;

    return (
      <MassPaymentGeneralColumn
        title={getTranslate('remittance.addPayout.mass.loading.templatesTitle')}
        templatesText={getTranslate(
          'remittance.addPayout.mass.readymadeTemplate.label'
        )}
        templates={[
          {
            title: getTranslate(
              'remittance.addPayout.mass.loading.templateCard'
            ),
            url: 'files/mass_remittance.csv',
            isAvailable: true,
          },
        ]}
      />
    );
  };

  renderAdditionalColumn = () => {
    const { getTranslate } = this.props;

    return (
      <MassPaymentAdditionalColumn
        title={getTranslate('remittance.addPayout.mass.attachFiles.label')}
        batch={this.state.batch}
        isFilePreview={this.state.isFilePreview}
        file={this.state.file}
        isFileLoading={this.state.isFileLoading}
        onChangeFile={(newFile) => this.validateFile(newFile)}
        onChangeField={this.changeField}
        onDeleteFile={this.resetData}
        error={this.state.error}
        switcherText={getTranslate('remittance.addPayout.detailsBtn')}
      />
    );
  };

  renderFooter = () => {
    const { getTranslate } = this.props;

    return (
      <MassPaymentFooter
        id='remittances'
        isFileLoading={this.state.isFileLoading}
        backUrl={path(`/${urlsMap.remittance}`)}
        isFilePreview={this.state.isFilePreview}
        isBatchPreview={this.state.isBatchPreview}
        backButtonText={getTranslate('remittance.addPayout.back.button')}
        createButtonText={
          this.state.isFilePreview && !this.state.isBatchPreview
            ? getTranslate('payouts.request.detailsBtn')
            : getTranslate('remittance.addPayout.sendRequest.button')
        }
        canCreate={
          !!this.state.batch && this.state.batch.isValid && !this.state.error
        }
        onCreate={() => {
          if (this.state.isFilePreview && !this.state.isBatchPreview) {
            this.setState({ isBatchPreview: true });
          } else {
            return this.onCreate();
          }
        }}
      />
    );
  };

  renderBatchPreview = () => {
    if (!this.state.batch || !this.state.idsToCheck) return null;

    return (
      <BatchPreview
        type='remittance'
        batch={this.state.batch}
        file={this.state.file}
        isDeleting={this.state.isDeleting}
        idsToCheck={this.state.idsToCheck}
        onDeleteRow={() => this.onDeleteRow()}
        onSelectToDelete={this.onSelectToDelete}
      />
    );
  };

  renderSubmitted = () => {
    return (
      <SubmittedForm
        id='remittance'
        title='remittance.addPayout.sendRequest.header'
        text='remittance.addPayout.sendRequest.text'
        status={this.state.status}
        secondaryButtonText='remittance.addPayout.back.button'
        onSecondaryButtonLink={path(`/${urlsMap.remittance}`)}
        primaryButtonText='remittance.addRecipientAcc.newRemittance.button'
        onPrimaryButtonClick={this.resetData}
      />
    );
  };

  changeField = (field: string, value: any) => {
    if (field === 'isFilePreview') {
      this.setIdsToCheck();
    }
    this.setState((state) => {
      return {
        ...state,
        [field]: value,
      };
    });
  };

  setIdsToCheck = (batch?) => {
    const source = batch || this.state.batch;
    this.setState(() => {
      return {
        idsToCheck:
          source.rows.reduce((result, item) => {
            result[item.id] = false;
            return result;
          }, {}) || null,
      };
    });
  };

  onDeleteRow = async () => {
    const { idsToCheck, batch } = this.state;
    if (!idsToCheck || !batch) return;

    try {
      this.setState({ isDeleting: true });
      const idsToDelete = Object.keys(idsToCheck)
        .filter((item) => idsToCheck[item])
        .map((item) => Number(item));
      const result = await removeItemFromBatch({
        remittancePaymentBatchItemId: idsToDelete,
        remittancePaymentBatchId: Number(batch.paymentOperationBatchId),
      });
      this.setState(
        {
          batch: result.batch,
          error: result.batch.validationError,
        },
        this.setIdsToCheck
      );
    } catch (e) {
      console.error(`Deleting items error`, e);
    } finally {
      this.setState({ isDeleting: false });
    }
  };

  onSelectToDelete = (data) => {
    const { idsToCheck, batch } = this.state;
    if (!batch || !idsToCheck) return;

    let newIdsToCheck;
    if (typeof data === 'number') {
      newIdsToCheck = { ...idsToCheck, [data]: !idsToCheck[data] };
    } else {
      newIdsToCheck = this.toggleAll(data);
    }
    this.setState({ idsToCheck: newIdsToCheck });
  };

  toggleAll = (data) => {
    const { idsToCheck } = this.state;
    if (!idsToCheck) return;
    if (data === 'checkAll') {
      return Object.keys(idsToCheck).reduce((acc, item) => {
        return { ...acc, [item]: true };
      }, {});
    }
    return Object.keys(idsToCheck).reduce((acc, item) => {
      return { ...acc, [item]: false };
    }, {});
  };

  validateFile = async (file) => {
    this.resetData();
    this.setState({ file });
    try {
      this.setState({ isFileLoading: true });
      await this.uploadFile();
    } catch (e) {
      console.error('File upload error', e);
      this.setState({ isFileLoading: false });
    }
  };

  async uploadFile() {
    const { file } = this.state;
    if (!file) return;

    this.setState({ isFileLoading: true });

    try {
      const batch = await uploadFile(file);
      if (batch.error) {
        this.setState({ error: batch.error });
      } else if (batch) {
        this.remittancePaymentBatchId = batch.id;
        this.startPolling(); // это надо?
      }
    } catch (error) {
      if (error.payload) {
        this.setState({
          error: error.payload.validationErrors?.file,
          isFileLoading: false,
        });
      } else {
        this.setState({
          isFileLoading: false,
        });
      }
    }
  }

  startPolling = () => {
    this.pollingTimerId = setInterval(() => {
      return getBatchPreview(this.remittancePaymentBatchId);
    }, POLLING_INTERVAL);
  };

  stopPolling = () => {
    clearInterval(this.pollingTimerId);
  };

  resetData = () => {
    this.setState({
      file: null,
      error: '',
      batch: null,
      isBatchPreview: false,
      isFilePreview: false,
      status: '',
    });
  };

  onCreate = () => {
    try {
      this.setState({ isLoading: true });
      confirmBatch(this.remittancePaymentBatchId);
    } catch (e) {
      console.error('Error on batch confirm', e);
      this.setState({ isLoading: false });
    }
  };

  onEvent = ({ data, name }) => {
    if (
      !this.remittancePaymentBatchId &&
      data.payload.remittancePaymentBatchId !== this.remittancePaymentBatchId
    )
      return;

    switch (name) {
      case Messages.Remittance_PaymentBatchPreview: {
        const { batch } = data.payload;
        if (batch.status !== BATCH_STATUSES.awaiting_confirm) {
          this.setState({
            error: batch.validationError,
            isFileLoading: false,
          });
        } else {
          this.setState({
            batch,
            error: batch.validationError,
            isFileLoading: false,
          });
        }
        if (
          batch.status === BATCH_STATUSES.awaiting_confirm ||
          batch.status === BATCH_STATUSES.error
        ) {
          this.stopPolling();
        }
        break;
      }
      case Messages.Remittance_PaymentBatchConfirm: {
        if (data.payload.validationErrors) {
          this.setState({ isLoading: false });
          return;
        }
        const { batch } = data.payload;
        if (!batch.error) {
          this.setState({
            isLoading: false,
            status: batch.status,
          });
        }
        break;
      }
      case Messages.Confirm_Psd2: {
        if (data.payload.confirmationDetails) {
          this.setState({ isLoading: false });
          this.props.dispatch(
            openModal({
              modalId: 'CodeVerification',
              content: data.payload,
            })
          );
        }
      }
    }
  };
}

export default connect()(addTranslation(RemittancePaymentMass));
