import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { isEqual } from 'lodash-es';
import { usePermission, useTranslation } from 'hooks';
import { RootState } from 'store';
import { updateProjectField } from 'actions/projects';
import checkFilters from 'helpers/checkFilters';
import {
  getProjectFiscalReceiptInfo,
  updateProjectFiscalReceiptInfo,
} from 'api/projects';
import RpcTypes from 'constants/rpcTypes';
import Form from 'components/ui/form';
import Button from 'components/ui/button';
import Input from 'components/ui/input';
import CustomSelect from 'components/ui/customSelect';
import Loader from 'components/ui/loader';
import showNotification from 'components/ui/notification/showNotification';
import './fiscalReceipt.scss';

interface FormValues {
  inn: string | null;
  group: string | null;
  taxationSystem: number | null;
  paymentAddress: string | null;
}

const initialState = {
  inn: null,
  group: null,
  taxationSystem: null,
  paymentAddress: null,
};

const FiscalReceipt: React.FC = () => {
  const dispatch = useDispatch();
  const getTranslate = useTranslation();
  const { isEnabled } = usePermission();
  const formValuesSync = useRef(true);
  const [isLoading, setLoading] = useState(false);
  const [formValues, setFormValues] = useState<FormValues>(initialState);
  const [initialFormValues, setInitialFormValues] = useState<FormValues | null>(
    null
  );
  const [errors, setErrors] = useState<{ inn?: string }>({});

  const { taxationSystem, projectId } = useSelector((state: RootState) => {
    const { CashVoucherTaxationSystem } = state.filtersValues;
    const { projectId } = state.projects.project;
    const { list: data = [], isLoading = false } =
      CashVoucherTaxationSystem || {};

    const list = data?.map(({ id, text }) => ({
      value: id,
      label: text,
    }));

    return {
      taxationSystem: {
        list,
        isLoading,
      },
      projectId,
    };
  });

  const canUpdate = isEnabled(RpcTypes.Project_CashVoucherSettingsUpdate);

  const fetchInfo = useCallback(async () => {
    setLoading(true);

    try {
      const data = await getProjectFiscalReceiptInfo({ projectId });
      setFormValues(data);
      setInitialFormValues(data);
    } catch (err: any) {
      console.error(err);
    } finally {
      setLoading(false);
    }
  }, [projectId]);

  useEffect(() => {
    checkFilters(['CashVoucherTaxationSystem']);
    fetchInfo();
  }, [fetchInfo]);

  useEffect(() => {
    // watch dirty values
    if (initialFormValues) {
      if (!isEqual(initialFormValues, formValues)) {
        if (!formValuesSync.current) {
          return;
        }

        dispatch(updateProjectField('canSaveFiscalReceipt', true));
        formValuesSync.current = false;
      } else {
        dispatch(updateProjectField('canSaveFiscalReceipt', false));
        formValuesSync.current = true;
      }
    }
    // eslint-disable-next-line
  }, [initialFormValues, formValues]);

  const handleChange = (formValue: Partial<FormValues>) => {
    setFormValues((prevState) => ({
      ...prevState,
      ...formValue,
    }));
  };

  const handleSubmit = async () => {
    setLoading(true);
    setErrors({});

    try {
      const data = await updateProjectFiscalReceiptInfo({
        projectId,
        ...formValues,
      });

      setInitialFormValues(data);
      showNotification({
        status: 'success',
        content: getTranslate('common.update.message'),
      });
    } catch (err: any) {
      const { validationErrors } = err?.payload || {};

      if (validationErrors) {
        setErrors({
          inn: validationErrors?.inn,
        });
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className='fiscal-receipt'>
      <div className='ui-form-fields card'>
        {isLoading ? <Loader /> : null}

        <Form customClass='ui-form-fields__form' onSubmit={handleSubmit}>
          <div className='ui-form-fields__container ui-form-fields__header'>
            <h1 className='ui-form-fields__title'>
              {getTranslate('fiscalReceipt.Info.label')}
            </h1>

            <p className='ui-form-fields__text'>
              {getTranslate('fiscalReceipt.Info.General.label')}
            </p>
          </div>

          <div className='ui-form-fields__container ui-form-fields__container_content'>
            <div className='ui-form-fields__item'>
              <Input
                id='iin'
                modern
                readOnly={!canUpdate}
                label={getTranslate('fiscalReceipt.detCard.Itn.label')}
                placeholder={getTranslate('fiscalReceipt.detCard.Itn.label')}
                tooltip={getTranslate('fiscalReceipt.detCard.Itn.tooltip')}
                value={formValues.inn || ''}
                error={errors.inn}
                onChange={(e) => {
                  handleChange({
                    inn: e.target.value,
                  });
                }}
                maxLength={12}
                cleaveOptions={{
                  numericOnly: true,
                }}
              />
            </div>
            <div className='ui-form-fields__item'>
              <Input
                id='group'
                modern
                readOnly={!canUpdate}
                label={getTranslate('fiscalReceipt.detCard.VenDet.label')}
                placeholder={getTranslate('fiscalReceipt.detCard.VenDet.label')}
                value={formValues.group || ''}
                onChange={(e) => {
                  handleChange({
                    group: e.target.value,
                  });
                }}
              />
            </div>
            <div className='ui-form-fields__item'>
              <CustomSelect
                id='taxationSystem'
                modern
                readOnly={!canUpdate}
                placeholder={getTranslate('fiscalReceipt.detCard.TaxSys.label')}
                label={getTranslate('fiscalReceipt.detCard.TaxSys.label')}
                isLoading={taxationSystem.isLoading}
                options={taxationSystem.list}
                value={taxationSystem.list.find(
                  ({ value }) => value === formValues.taxationSystem
                )}
                onChange={({ value }) => {
                  handleChange({
                    taxationSystem: value,
                  });
                }}
              />
            </div>
            <div className='ui-form-fields__item'>
              <Input
                id='paymentAddress'
                modern
                readOnly={!canUpdate}
                tooltip={getTranslate('fiscalReceipt.detCard.Address.tooltip')}
                label={getTranslate('fiscalReceipt.detCard.Address.label')}
                placeholder={getTranslate(
                  'fiscalReceipt.detCard.Address.label'
                )}
                value={formValues.paymentAddress || ''}
                onChange={(e) => {
                  handleChange({
                    paymentAddress: e.target.value,
                  });
                }}
              />
            </div>
          </div>

          <div className='ui-form-fields__container ui-form-fields__container_footer utils-flex'>
            <Button
              text={getTranslate('common.save.button')}
              status='primary'
              type='submit'
              disabled={!canUpdate}
            />
          </div>
        </Form>
      </div>
    </div>
  );
};

export { FiscalReceipt };
