import Confirm from './Confirm';
import { GraphDetailsContainer as GraphDetails } from './graphDetails/GraphDetailsContainer';
import EditLayout from './editLayout';
import TwoFactorAuth from './TwoFactorAuth';
import Confirm2FA from './confirm2FA';
import CookieSettings from './cookieSettings';
import EditLocale from './EditLocale';
import CodeVerification from './codeVerification';
import { Notification } from './Notification';
import EditApsIcon from './editApsIcon';
import ConfirmTelegram from './confirmTelegram';
import AddHelpArticle from './addHelpArticle';
import PaymentFrame from './paymentFrame/PaymentFrame';
import CSVUpload from './CSVUpload';
import ChargebackTimeline from './ChargebackTimeline';
import CreateSupportTicket from './createSupportTicket';
import ContactSales from './contactSales';
import { ModalEditChart } from './graphDetails/ModalEditChart';
import MafConfirm from './mafConfirm/MafConfirm';
import RedirectHistory from './redirectHistory/RedirectHistory';
import CreateCallbackForm from './createCallbackForm';
import RecurringSettingsForm from './recurringSettingsForm';
import AddAdditionalFeature from './addAdditionalFeature';
import ConfirmInvoiceSubscription from './ConfirmInvoiceSubscription';
import UploadFinancialData from './uploadFinancialData';
import PaymentPageDesigner from './PaymentPageDesigner';
import { BankAccountCreateForm } from './bankAccountCreateForm';
import { CodatModal } from './CodatModal';
import {
  FiscalReceiptPaymentSettings,
  FiscalReceiptPositionSettings,
} from './fiscalReceiptSettings';
import { BalanceRequestNote } from './balanceRequestNote';
import { ButchUserEdit } from './butchUserEdit';

export default {
  Confirm,
  GraphDetails,
  EditLocale,
  EditLayout,
  CookieSettings,
  TwoFactorAuth,
  Confirm2FA,
  CodeVerification,
  Notification,
  ConfirmTelegram,
  EditApsIcon,
  AddHelpArticle,
  PaymentFrame,
  CSVUpload,
  ModalEditChart,
  ChargebackTimeline,
  CreateSupportTicket,
  ContactSales,
  MafConfirm,
  RedirectHistory,
  CreateCallbackForm,
  AddAdditionalFeature,
  RecurringSettingsForm,
  ConfirmInvoiceSubscription,
  UploadFinancialData,
  PaymentPageDesigner,
  BankAccountCreateForm,
  FiscalReceiptPaymentSettings,
  FiscalReceiptPositionSettings,
  CodatModal,
  BalanceRequestNote,
  ButchUserEdit,
};
