import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';

import { BrowserRouter as Router } from 'react-router-dom';
import 'core-js/stable';
import 'regenerator-runtime/runtime';
import './polifyll';

import StartupContainer from './StartupContainer';
import { store } from 'store';
import Repository from 'helpers/Repository';
import 'styles/index.scss';

// App-wide i18n settings.
import { addLocaleData } from 'react-intl';
import en from 'react-intl/locale-data/en';
import ru from 'react-intl/locale-data/ru';
import { setupSentry } from 'setupSentry';
import Env from 'helpers/Env';

addLocaleData([...en, ...ru]);
Repository.set('store', store);
Env.isProduction() && setupSentry();

ReactDOM.render(
  <Provider store={store}>
    <Router>
      <StartupContainer />
    </Router>
  </Provider>,
  document.querySelector('#root')
);
