import React, { useState } from 'react';
import SyntaxHighlighter from 'react-syntax-highlighter';
import { IntlProps, addTranslation } from 'decorators/addTranslation';
import { resendPaymentCallback } from 'api/payment';
import Button from 'components/ui/button';
import CopyToClipboard from 'components/ui/copyToClipboard';
import './callbackInfo.scss';

interface Props extends IntlProps {
  callback: {
    callbackBody: string;
    callbackAttempts: string;
    callbackCreationDate: string;
    callbackLastAttempt: string;
    callbackStatus: string;
  };
  transactionId: string;
  operationId: string;
  resendEnabled: boolean;
}

const _CallbackInfo: React.FC<Props> = ({
  callback,
  transactionId,
  operationId,
  resendEnabled,
  getTranslate,
}) => {
  const [resending, setResending] = useState(false);
  const {
    callbackBody,
    callbackAttempts,
    callbackCreationDate,
    callbackLastAttempt,
    callbackStatus,
  } = callback;

  const parseCallback = () => {
    try {
      return JSON.stringify(JSON.parse(callbackBody), undefined, 4);
    } catch (err) {
      console.error(err);
    }
  };

  const resend = async () => {
    setResending(true);

    try {
      await resendPaymentCallback(transactionId, operationId);
    } catch (err) {
      console.error(err);
    } finally {
      setResending(false);
    }
  };

  const parsedData = parseCallback() ?? '';

  return (
    <div className='callback'>
      <div className='callback__details'>
        <div className='callback__detail'>
          <h4 className='text-m callback__detail-title'>
            {getTranslate('payments.data.callbackstatus')}
          </h4>
          <p className='text-m callback__detail-text'>{callbackStatus}</p>
        </div>
        <div className='callback__detail'>
          <h4 className='text-m callback__detail-title'>
            {getTranslate('payments.data.attempts')}
          </h4>
          <p className='text-m callback__detail-text'>{callbackAttempts}</p>
        </div>
        <div className='callback__detail'>
          <h4 className='text-m callback__detail-title'>
            {getTranslate('payments.data.creationtime')}
          </h4>
          <p className='text-m callback__detail-text'>{callbackCreationDate}</p>
        </div>
        <div className='callback__detail'>
          <h4 className='text-m callback__detail-title'>
            {getTranslate('payments.data.lastattempts')}
          </h4>
          <p className='text-m callback__detail-text'>{callbackLastAttempt}</p>
        </div>
      </div>

      <div className='callback__body'>
        <div className='callback__body-actions'>
          <CopyToClipboard text={parsedData}>
            <Button
              size='normal'
              status='outline'
              text={getTranslate('payments.single.callback.copy.button')}
            />
          </CopyToClipboard>

          {resendEnabled ? (
            <Button
              size='normal'
              status='primary'
              text={getTranslate(
                'payments.single.callback.resendCallback.button'
              )}
              onClick={resend}
              loading={resending}
            />
          ) : null}
        </div>

        <SyntaxHighlighter
          showLineNumbers
          language='javascript'
          lineNumberStyle={{
            backgroundColor: '#fafafa',
            color: '#666',
            padding: '3px 10px',
            marginRight: 20,
          }}
          wrapLines
          lineProps={{
            id: 'line-wraper',
          }}
          useInlineStyles={false}
          customStyle={{
            width: '100%',
            height: '100%',
            padding: 0,
            overflow: 'auto',
            backgroundColor: '#fff',
            fontFamily: 'monospace',
          }}>
          {parsedData}
        </SyntaxHighlighter>
      </div>
    </div>
  );
};

export const CallbackInfo = addTranslation(_CallbackInfo);
