enum RpcTypes {
  Balance_Contracts = 'Balance_Contracts',
  Balance_Get = 'Balance_Get',
  FinancialReport_ListRequest = 'FinancialReport_ListRequest',
  FinancialReport_DownloadRequest = 'FinancialReport_DownloadRequest',
  FinancialReport_ListStatus = 'FinancialReport_ListStatus',
  FinancialReport_DownloadStatus = 'FinancialReport_DownloadStatus',
  Rpc_FinancialReport_DownloadRequest_Success = 'Rpc_FinancialReport_DownloadRequest_Success',
  FinancialData_FileList = 'FinancialData_FileList',
  FinancialData_FileDelete = 'FinancialData_FileDelete',
  FinancialData_FileDownloadRequest = 'FinancialData_FileDownloadRequest',
  FinancialData_FileDownloadReady = 'FinancialData_FileDownloadReady',
  FinancialData_FileUpdated = 'FinancialData_FileUpdated',
  FinancialData_StatementView = 'FinancialData_StatementView',

  Dictionary_Timezones = 'Dictionary_Timezones',
  Dictionary_PayoutCurrencies = 'Dictionary_PayoutCurrencies',
  Dictionary_PayoutPaymentMethods = 'Dictionary_PayoutPaymentMethods',
  Dictionary_PayoutPaymentMethodRules = 'Dictionary_PayoutPaymentMethodRules',
  Dictionary_TableColumns = 'Dictionary_TableColumns',
  Dictionary_AvailableValues = 'Dictionary_AvailableValues',
  DictionariesUpdated = 'DictionariesUpdated',
  Dictionary_Load = 'Dictionary_Load',

  FilterSettings_Get = 'FilterSettings_Get',
  FilterSettings_Payments = 'FilterSettings_Payments',

  Localize_Dictionaries = 'Localize_Dictionaries',
  Localization_Download = 'Localization_Download',
  Localization_Upload = 'Localization_Upload',
  Localize_Authorized = 'Localize_Authorized',

  Localize_LexemesList = 'Localize_LexemesList',
  Localize_CreateLexeme = 'Localize_CreateLexeme',
  Localize_UpdateLexeme = 'Localize_UpdateLexeme',
  Localize_DeleteLexeme = 'Localize_DeleteLexeme',
  Localize_Sync = 'Localize_Sync',
  Localize_TicketList = 'Localize_TicketList',

  Payments_Payment = 'Payments_Payment',
  PaymentOperation_AvailableActions = 'PaymentOperation_AvailableActions',
  PaymentOperation_TransactionStatus = 'PaymentOperation_TransactionStatus',
  PaymentOperation_Refund = 'PaymentOperation_Refund',
  PaymentOperation_PreFilledPayout = 'PaymentOperation_PreFilledPayout',
  Payments_ResendCallback = 'Payments_ResendCallback',
  PaymentOperation_Capture = 'PaymentOperation_Capture',
  PaymentOperation_Cancel = 'PaymentOperation_Cancel',

  Payments_List = 'Payments_List',

  BulkPayouts_List = 'BulkPayouts_List',
  PaymentOperation_Payout = 'PaymentOperation_Payout',
  PaymentOperation_StatusUpdate = 'PaymentOperation_StatusUpdate',
  PaymentOperation_Status = 'PaymentOperation_Status',
  PaymentOperation_ClarificationProceed = 'PaymentOperation_ClarificationProceed',
  PaymentOperation_ClarificationReject = 'PaymentOperation_ClarificationReject',
  PaymentOperationGenerator_GenerateInit = 'PaymentOperationGenerator_GenerateInit',
  PaymentOperationGenerator_GenerateAvailability = 'PaymentOperationGenerator_GenerateAvailability',
  PaymentOperation_PrefilledPayoutClarification = 'PaymentOperation_PrefilledPayoutClarification',
  PaymentIssue_RequestActions = 'PaymentIssue_RequestActions',
  PaymentIssue_PerformAction = 'PaymentIssue_PerformAction',

  BulkPayouts_Upload = 'BulkPayouts_Upload',
  BulkPayouts_UploadPayout = 'BulkPayouts_UploadPayout',
  BulkPayouts_UploadRefund = 'BulkPayouts_UploadRefund',
  BulkPayouts_UploadCapture = 'BulkPayouts_UploadCapture',
  BulkPayouts_UploadCancel = 'BulkPayouts_UploadCancel',
  BulkPayouts_RemoveOperations = 'BulkPayouts_RemoveOperations',
  BulkPayouts_Confirm = 'BulkPayouts_Confirm',
  BulkPayouts_View = 'BulkPayouts_View',
  BulkPayouts_Preview = 'BulkPayouts_Preview',
  PaymentOperationBatch_Uploaded = 'PaymentOperationBatch_Uploaded',
  FileDownload_TemplatePayoutCsv = 'FileDownload_TemplatePayoutCsv',
  PaymentOperationBatch_Updated = 'PaymentOperationBatch_Updated',
  Payments_DashboardOperations = 'Payments_DashboardOperations',
  BulkPayouts_ClarificationFieldUpdate = 'BulkPayouts_ClarificationFieldUpdate',
  BulkPayouts_ClarificationProceed = 'BulkPayouts_ClarificationProceed',
  BulkPayouts_ClarificationReject = 'BulkPayouts_ClarificationReject',
  PaymentOperationBatch_ClarificationUploaded = 'PaymentOperationBatch_ClarificationUploaded',
  MotoPayment_Create = 'MotoPayment_Create',
  MotoPayment_Finalize = 'MotoPayment_Finalize',

  GoogleAuth_EnableParams = 'GoogleAuth_EnableParams',
  GoogleAuth_Enable = 'GoogleAuth_Enable',
  GoogleAuth_Disable = 'GoogleAuth_Disable',

  UserApiToken_List = 'UserApiToken_List',
  UserApiToken_Generate = 'UserApiToken_Generate',
  UserApiToken_Remove = 'UserApiToken_Remove',

  Profile_Get = 'Profile_Get',
  Profile_Update = 'Profile_Update',
  Profile_UpdateTimezone = 'Profile_UpdateTimezone',
  Profile_UpdateLanguage = 'Profile_UpdateLanguage',
  Profile_AvatarUpload = 'Profile_AvatarUpload',
  Auth_Login = 'Auth_Login',
  Auth_ConfirmSecondFactorState = 'Auth_ConfirmSecondFactorState',
  Auth_ConfirmSecondFactorStateRequest = 'Auth_ConfirmSecondFactorStateRequest',
  Auth_PasswordChange = 'Auth_PasswordChange',
  Auth_PasswordReset = 'Auth_PasswordReset',
  Auth_PasswordResetComplete = 'Auth_PasswordResetComplete',
  Auth_Ping = 'Auth_Ping',
  Auth_PasswordResetCheckToken = 'Auth_PasswordResetCheckToken',
  Auth_Logout = 'Auth_Logout',
  Auth_LogoutFromAll = 'Auth_LogoutFromAll',
  UserActivity_CurrentUser = 'UserActivity_CurrentUser',
  UserConfig_Set = 'UserConfig_Set',
  UserConfig_Get = 'UserConfig_Get',

  ReportTemplateTaskHistory_List = 'ReportTemplateTaskHistory_List',
  ReportTemplateTaskHistory_ConstructNow = 'ReportTemplateTaskHistory_ConstructNow',

  ReportTemplate_List = 'ReportTemplate_List',
  ReportTemplate_Delete = 'ReportTemplate_Delete',
  ReportTemplate_RemoveTemplateFlag = 'ReportTemplate_RemoveTemplateFlag',
  ReportTemplateTaskHistory_Delete = 'ReportTemplateTaskHistory_Delete',
  ReportTemplate_Activate = 'ReportTemplate_Activate',
  ReportTemplate_Deactivate = 'ReportTemplate_Deactivate',
  ReportTemplate_Get = 'ReportTemplate_Get',
  ReportTemplate_Create = 'ReportTemplate_Create',
  ReportTemplate_ListTemplates = 'ReportTemplate_ListTemplates',
  ReportTemplateTaskHistory_Update = 'ReportTemplateTaskHistory_Update',
  Dictionary_ReportAdditionalFields = 'Dictionary_ReportAdditionalFields',
  ReportTemplate_ListColumns = 'ReportTemplate_ListColumns',

  Notification = 'Notification',

  Team_ListUsers = 'Team_ListUsers',
  Team_ListActivities = 'Team_ListActivities',
  Team_CreateUser = 'Team_CreateUser',
  Team_UpdateUser = 'Team_UpdateUser',
  Team_DisableUser = 'Team_DisableUser',
  Team_EnableUser = 'Team_EnableUser',
  Team_DeleteUser = 'Team_DeleteUser',
  Team_GetUser = 'Team_GetUser',

  MessagesPermissions = 'MessagesPermissions',

  //analytics
  Analytics_Ts = 'Analytics_Ts',
  Analytics_CreateChart = 'Analytics_CreateChart',
  Analytics_GetUserCharts = 'Analytics_GetUserCharts',
  Analytics_GetChart = 'Analytics_GetChart',
  Analytics_DeleteChart = 'Analytics_DeleteChart',
  Analytics_MoveChart = 'Analytics_MoveChart',
  Analytics_AdvancedAnalytics = 'Analytics_AdvancedAnalytics',
  Analytics_GetChartFilters = 'Analytics_GetChartFilters',
  Analytics_EditChart = 'Analytics_EditChart',

  DevTest_CheckFeature = 'DevTest_CheckFeature',
  Auth_ConfirmSecondFactorRequest = 'Auth_ConfirmSecondFactorRequest',

  Balance_RealTime = 'Balance_RealTime',
  Balance_Statement = 'Balance_Statement',
  Balance_Report = 'Balance_Report',
  BalanceTransfer_List = 'BalanceTransfer_List',
  BalanceTransfer_Get = 'BalanceTransfer_Get',
  BalanceTransfer_Calculate = 'BalanceTransfer_Calculate',
  BalanceTransfer_Confirm = 'BalanceTransfer_Confirm',
  BalanceTransfer_Cancel = 'BalanceTransfer_Cancel',
  SettlementRequest_List = 'SettlementRequest_List',
  SettlementRequest_Get = 'SettlementRequest_Get',
  SettlementRequest_Calculate = 'SettlementRequest_Calculate',
  SettlementRequest_Confirm = 'SettlementRequest_Confirm',
  SettlementRequest_Cancel = 'SettlementRequest_Cancel',
  SettlementRequest_BankAccounts = 'SettlementRequest_BankAccounts',
  CurrencyRates_List = 'CurrencyRates_List',
  SettlementRequest_RequestPaymentOrderMail = 'SettlementRequest_RequestPaymentOrderMail',

  SubscriptionPayment_List = 'SubscriptionPayment_List',
  SubscriptionPayment_Info = 'SubscriptionPayment_Info',
  SubscriptionPayment_Settings = 'SubscriptionPayment_Settings',
  SubscriptionPayment_Update = 'SubscriptionPayment_Update',
  SubscriptionPayment_Cancel = 'SubscriptionPayment_Cancel',
  SubscriptionPayment_RetryCancel = 'SubscriptionPayment_RetryCancel',

  Invoice_List = 'Invoice_List',
  Invoice_Create = 'Invoice_Create',
  Invoice_Cancel = 'Invoice_Cancel',
  Invoice_FormConfig = 'Invoice_FormConfig',
  InvoicesUpdated = 'InvoicesUpdated',
  Invoice_CreateSubscription = 'Invoice_CreateSubscription',

  // Request types
  Auth_ConfirmSecondFactor = 'Auth_ConfirmSecondFactor',
  Confirm = 'Confirm',
  Confirm_Reject = 'Confirm_Reject',
  Confirm_Accept = 'Confirm_Accept',
  Confirm_Psd2 = 'Confirm_Psd2',
  Regenerate_Psd2 = 'Regenerate_Psd2',

  GET_WL = 'GET_WL',

  //stage landing
  DemoStage_Project = 'DemoStage_Project',
  Onboarding_BillingTariffs = 'Onboarding_BillingTariffs',
  DemoStage_LandingData = 'DemoStage_LandingData',
  Onboarding_DemoStageInvitation = 'Onboarding_DemoStageInvitation',
  Onboarding_ContactSales = 'Onboarding_ContactSales',
  Onboarding_AcceptPricing = 'Onboarding_AcceptPricing',
  Onboarding_Business_Docs = 'Onboarding_Business_Docs',

  //Projects
  Project_Get = 'Project_Get',
  Project_SaveRedirectSettings = 'Project_SaveRedirectSettings',
  Project_SaveEditorStyles = 'Project_SavePaymentPageDesignerVersionFive',
  Project_UrlHistory = 'Project_UrlHistory',
  Project_ChangePaymentMethodStatus = 'Project_ChangePaymentMethodStatus',
  Project_UpdateSalt = 'Project_UpdateSalt',
  Project_DeleteSalt = 'Project_DeleteSalt',
  ProjectCallbackSettings_List = 'ProjectCallbackSettings_List',
  ProjectCallbackSettings_Update = 'ProjectCallbackSettings_Update',
  ProjectCallbackSettings_Create = 'ProjectCallbackSettings_Create',
  ProjectCallbackSettings_Delete = 'ProjectCallbackSettings_Delete',
  Project_CashVoucherSettingsGet = 'Project_CashVoucherSettingsGet',
  Project_CashVoucherSettingsUpdate = 'Project_CashVoucherSettingsUpdate',

  Rcs_ListBlackWhite = 'Rcs_ListBlackWhite',
  Rcs_ListFraud = 'Rcs_ListFraud',
  Rcs_CreateElement = 'Rcs_CreateElement',
  Rcs_CreateElementByTransaction = 'Rcs_CreateElementByTransaction',
  Rcs_DeleteElement = 'Rcs_DeleteElement',
  Rcs_transactionContentTypes = 'Rcs_transactionContentTypes',
  Rcs_BulkCreateElement = 'Rcs_BulkCreateElement',
  Rcs_ProjectContentType = 'Rcs_ProjectContentType',

  Redirect = 'Redirect',
  Auth_LoginToken = 'Auth_LoginToken',
  Auth_LoginAsSupport = 'Auth_LoginAsSupport',
  Auth_Jwt = 'Auth_Jwt',

  PaymentOperationReference_List = 'PaymentOperationReference_List',
  PaymentOperationReference_Create = 'PaymentOperationReference_Create',

  Chargeback_List = 'Chargeback_List',
  Chargeback_Info = 'Chargeback_Info',
  Chargeback_Accept = 'Chargeback_Accept',
  Chargeback_DisputeStart = 'Chargeback_DisputeStart',
  Chargeback_DisputeUpdated = 'Chargeback_DisputeUpdated',
  Chargeback_DisputeStatus = 'Chargeback_DisputeStatus',
  Chargeback_DisputeCommit = 'Chargeback_DisputeCommit',
  Chargeback_DisputeDelete = 'Chargeback_DisputeDelete',

  ApsIcon_AvailablePaymentMethods = 'ApsIcon_AvailablePaymentMethods',
  ApsIcon_UsedPaymentMethods = 'ApsIcon_UsedPaymentMethods',
  ApsIcon_List = 'ApsIcon_List',
  ApsIcon_ListVersions = 'ApsIcon_ListVersions',
  ApsIcon_Create = 'ApsIcon_Create',
  ApsIcon_Update = 'ApsIcon_Update',
  ApsIcon_Delete = 'ApsIcon_Delete',
  ApsIcon_Restore = 'ApsIcon_Restore',
  ApsIcon_Unload = 'ApsIcon_Unload',

  Confirm_Telegram = 'Confirm_Telegram',
  Profile_TelegramConfirmInit = 'Profile_TelegramConfirmInit',
  Profile_TelegramDeactivate = 'Profile_TelegramDeactivate',
  Profile_TelegramConfirmVerify = 'Profile_TelegramConfirmVerify',

  UserNotification_List = 'UserNotification_List',
  UserNotification_Counters = 'UserNotification_Counters',
  UserNotification_MarkAsRead = 'UserNotification_MarkAsRead',
  UserNotification_Delete = 'UserNotification_Delete',
  UserNotification_DeleteAll = 'UserNotification_DeleteAll',
  UserNotificationSetting_List = 'UserNotificationSetting_List',
  UserNotificationSetting_Save = 'UserNotificationSetting_Save',
  UserNotification = 'UserNotification',
  RateLimit_Info = 'RateLimit_Info',

  Article_Create = 'Article_Create',
  Article_View = 'Article_View',
  Article_Menu = 'Article_Menu',
  Article_Update = 'Article_Update',
  Article_Publish = 'Article_Publish',
  Article_Draft = 'Article_Draft',
  Article_Delete = 'Article_Delete',
  Article_Search = 'Article_Search',
  Article_SetSortMenuIndex = 'Article_SetSortMenuIndex',

  // Merchant onboarding process
  Maf_Get = 'Maf_Get',
  Maf_GetDigest = 'Maf_GetDigest',
  Maf_List = 'Maf_List',
  Maf_Update = 'Maf_Update',
  Maf_Commit = 'Maf_Commit',
  Maf_Status = 'Maf_Status',
  Maf_Error = 'Maf_Error',
  Maf_WasOpen = 'Maf_WasOpen',
  Dictionary_Maf = 'Dictionary_Maf',
  Maf_DownloadDocument = 'Maf_DownloadDocument',
  Maf_RequestCommercialOffer = 'Maf_RequestCommercialOffer',
  Maf_CommercialOfferCallback = 'commercial_offer_callback',
  Onboarding_CommercialOfferDownloaded = 'Onboarding_CommercialOfferDownloaded',
  Maf_ApproveCommercialOffer = 'Maf_ApproveCommercialOffer',
  Dictionary_RequestCrmEnum = 'Dictionary_RequestCrmEnum',
  LegalEntityOnProcessStatusUpdate = 'LegalEntityOnProcessStatusUpdate',

  Qa_EnableApiLogs = 'Qa_EnableApiLogs',
  Qa_GetApiLogs = 'Qa_GetApiLogs',

  //admin
  User_List = 'User_List',
  User_Get = 'User_Get',
  User_Create = 'User_Create',
  User_Update = 'User_Update',
  User_BulkUpdate = 'User_BulkUpdate',

  User_Delete = 'User_Delete',
  User_Disable = 'User_Disable',
  User_Enable = 'User_Enable',
  User_ActionAvailability = 'User_ActionAvailability',

  //test email send
  DevTest_mail = 'DevTest_mail',

  ClientTab_Info = 'ClientTab_Info',
  ClientTab_Update = 'ClientTab_Update',

  //Reconciliation
  Reconciliation_Get = 'Reconciliation_Get',
  Reconciliation_SetData = 'Reconciliation_SetData',

  //HelpDesk
  HelpTips_List = 'HelpTips_List',
  HelpTips_MarkAsRead = 'HelpTips_MarkAsRead',

  SupportChat_MessageMarkAsRead = 'SupportChat_MessageMarkAsRead',
  SupportChat_Messages = 'SupportChat_Messages',
  SupportChat_Message = 'SupportChat_Message',
  Chat_Message = 'Chat_Message',

  //Remittance
  Remittance_ListRecipientAccounts = 'Remittance_ListRecipientAccounts',
  Remittance_CreateRecipientAccount = 'Remittance_CreateRecipientAccount',
  Remittance_GetRecipientAccount = 'Remittance_GetRecipientAccount',
  Remittance_ApproveRecipientAccount = 'Remittance_ApproveRecipientAccount',
  Remittance_DeleteRecipientAccount = 'Remittance_DeleteRecipientAccount',
  Remittance_UpdateRecipientAccount = 'Remittance_UpdateRecipientAccount',
  Remittance_ListPayments = 'Remittance_ListPayments',
  Remittance_CreatePayment = 'Remittance_CreatePayment',
  RemittanceQueue_StatusUpdated = 'RemittanceQueue_StatusUpdated',
  Remittance_GetPayment = 'Remittance_GetPayment',
  Remittance_PaymentBatchPreview = 'Remittance_PaymentBatchPreview',
  Remittance_PaymentBatchConfirm = 'Remittance_PaymentBatchConfirm',
  Remittance_PaymentBatchUpdated = 'Remittance_PaymentBatchUpdated',
  Remittance_PaymentBatchItemUpdated = 'Remittance_PaymentBatchItemUpdated',
  Remittance_PaymentBatchRemoveItem = 'Remittance_PaymentBatchRemoveItem',
  Remittance_PaymentBatchList = 'Remittance_PaymentBatchList',
  Remittance_PaymentBatchView = 'Remittance_PaymentBatchView',

  GetStarted_View = 'GetStarted_View',
  AdditionalFeature_List = 'AdditionalFeature_List',
  AdditionalFeature_Delete = 'AdditionalFeature_Delete',
  AdditionalFeature_Update = 'AdditionalFeature_Update',
  AdditionalFeature_Create = 'AdditionalFeature_Create',
  AdditionalFeature_UpdateSortIndex = 'AdditionalFeature_UpdateSortIndex',

  BankAccount_List = 'BankAccount_List',
  BankAccount_Create = 'BankAccount_Create',
  BankAccount_SendForApproval = 'BankAccount_SendForApproval',
  BankAccount_View = 'BankAccount_View',
  BankAccount_Update = 'BankAccount_Update',
  BankAccount_Updated = 'BankAccount_Updated',
  BankAccount_Archivate = 'BankAccount_Archivate',

  //Accounting data request
  AccountingDataRequest_List = 'AccountingDataRequest_List',
  AccountingDataRequest_Submit = 'AccountingDataRequest_Submit',
}

export const Messages = RpcTypes;
export default RpcTypes;
