/* eslint-disable max-lines */
import React, { Component } from 'react';
import Tooltip from 'react-tooltip';
import classNames from 'classnames';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { connect } from 'react-redux';
import { StoreProps } from 'store';
import { isEqual } from 'lodash-es';
import { stopRecurringRetries } from 'api/recurring';
import { openModal } from 'actions/modal';
import DateHelpers from 'helpers/Date';
import RecurringCardPageUI from 'types/RecurringCardPageUI';
import { RecurringSettingsHistory } from './settings/RecurringSettingsTypes';
import SavedRecurring from 'types/savedEntity/SavedRecurring';
import statusList from 'constants/statusList';
import Mapper from 'helpers/Mapper';
import Messages from 'constants/rpcTypes';
import permissionReasons from 'constants/permissionReasons';
import breakpoints from 'constants/breakpoints';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import Animation from 'components/ui/animation';
import CustomScrollbar from 'components/ui/customScrollbar';
import NoContent from 'components/ui/noContent';
import Button from 'components/ui/button';
import RoundButton from 'components/ui/roundButton';
import OperationInfo from 'components/paymentCard/operationInfo';
import OperationItem from 'components/paymentCard/operationItem/OperationItem';
import Teaser from 'components/ui/teaser';
import CopyToClipboard from 'components/ui/copyToClipboard';
import PaymentInfo from 'components/paymentCard/paymentInfo';
import Loader from 'components/ui/loader';
import SettingsHistory from './settings/settingsHistory';
import Icon from 'components/ui/icon';
import LabelStatus from 'components/ui/labelStatus';
import noContent from 'images/no-content.png';
import './recurringCard.scss';

type paymentType = 'init' | 'subs';
type orderType = 'asc' | 'desc';

interface OwnProps {
  recurring: SavedRecurring;
  historyList: RecurringSettingsHistory;
  isSettingsLoading: boolean;
  isLoading: boolean;
  isShowHistory: boolean;
  syncPaymentUI: (id: string, ui: RecurringCardPageUI) => void;
  openSettingsForm: () => void;
  toggleHistory: () => void;
  fetchData: () => void;
}

type Props = OwnProps & StoreProps & IntlProps & WithPermissions;

interface State {
  pageUI: RecurringCardPageUI;
  isOpenedPaymentInfo: boolean;
}

class RecurringCard extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageUI: { ...props.recurring?.ui },
      isOpenedPaymentInfo: this.setPaymentInfoState(),
    };
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { recurring, syncPaymentUI } = this.props;
    const { recurring: prevRecurring } = prevProps;

    // check for late props updating
    if (!recurring || !prevRecurring) {
      return;
    }

    // sync with redux store data if user move between payments cards
    if (prevRecurring.id !== recurring.id) {
      syncPaymentUI(prevRecurring.id, prevState.pageUI);
    }

    // set data to local state if props data changed
    if (!isEqual(prevRecurring, recurring)) {
      Tooltip.rebuild();

      if (recurring?.ui) {
        let pageUI = { ...recurring.ui } as RecurringCardPageUI;

        // if payment data updated but it doesnt have initial selectedId and retry operations haven't been opened
        if (!pageUI.selectedOperationId && !pageUI.isRetriesOpen) {
          const currentPaymentType = this.getInitialPaymentType();

          pageUI = {
            ...pageUI,
            selectedOperationId: recurring?.data
              ? this.getInitialSelectedOperation(currentPaymentType)
              : '',
            currentPaymentType,
            initOperationsIsOpened: currentPaymentType === 'init',
          };
        }

        this.setState({
          pageUI,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  getInitialPaymentType = (): paymentType => {
    const data = this.getCurrentPaymentData('subs');
    return !data ? 'init' : 'subs';
  };

  getInitialSelectedOperation = (type: paymentType) => {
    // get initial selectedId for mark current operation
    const data = this.getCurrentPaymentData(type);
    const { operations } = data || {};

    if (!operations) {
      return '';
    }

    const currentOperations = this.getCurrentOperations(operations);
    const { operationId } =
      this.getCurrentSelectedOperation(currentOperations) || {};

    return operationId ?? '';
  };

  getCurrentOperations = (operations: any[]) => {
    // check if retries is open and decide to show operations for retry
    const {
      pageUI: { parentSelectedOperationId, isRetriesOpen },
    } = this.state;
    let copyOperations = [...operations];

    if (isRetriesOpen) {
      const operation = copyOperations.find(
        ({ operationId }) => operationId === parentSelectedOperationId
      );

      if (operation) {
        const { recurring_retry_operations } = operation;
        copyOperations = [...recurring_retry_operations];
      }
    }

    return copyOperations;
  };

  getCurrentSelectedOperation = (currentOperations) => {
    // sort operations and get selected operation
    const {
      pageUI: { operationOrder },
    } = this.state;

    const sortedOperations = this.getSortedOperations(
      currentOperations,
      operationOrder
    );

    return this.getSelectedOperation(sortedOperations);
  };

  getSortedOperations = (operations: any[], order: orderType) => {
    // sort operations by dates
    return operations.sort((o1, o2) => {
      const date1 = +DateHelpers.createDate(
        o1.creationDate,
        'datetime'
      ).toDate();
      const date2 = +DateHelpers.createDate(
        o2.creationDate,
        'datetime'
      ).toDate();

      if (order === 'asc') {
        return date1 - date2;
      }

      return date2 - date1;
    });
  };

  getSelectedOperation = (operations) => {
    // get operation by id or first one by default
    const {
      pageUI: { selectedOperationId },
    } = this.state;

    return (
      operations.find(
        ({ operationId }) => selectedOperationId === operationId
      ) || operations[0]
    );
  };

  getCurrentPaymentData = (type: paymentType) => {
    const { recurring } = this.props;

    if (!recurring || !recurring.data) return;

    if (recurring.data.error) {
      return recurring.data;
    }

    return type === 'init'
      ? recurring.data.registrationInfo
      : recurring.data.subscriptionInfo;
  };

  changeSort = () => {
    const {
      pageUI: { operationOrder },
    } = this.state;

    this.changePageUI({
      operationOrder: operationOrder === 'desc' ? 'asc' : 'desc',
    });
  };

  changePageUI = (
    newPageUI: Partial<RecurringCardPageUI>,
    callback?: () => void
  ) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        pageUI: {
          ...prevState.pageUI,
          ...newPageUI,
        },
      }),
      callback
    );
  };

  handleResize = () => {
    if (document.body.clientWidth < breakpoints.commonTabletL) {
      this.closePaymentInfo();
    } else {
      this.openPaymentInfo();
    }
  };

  openPaymentInfo = () => {
    this.setState({ isOpenedPaymentInfo: true });
  };

  closePaymentInfo = () => {
    this.setState({ isOpenedPaymentInfo: false });
  };

  setPaymentInfoState = () => {
    return document.body.clientWidth >= breakpoints.commonTabletL;
  };

  getStatusTooltip = (status: string, retryDate: string) => {
    const { getTranslate } = this.props;

    switch (status) {
      case 'retry in progress':
        return getTranslate('payment.single.retryprogress.label');
      case 'retries active':
        if (retryDate) {
          return (
            <div>
              {getTranslate('payment.single.retryactivedate.label')}
              <span className='bold'> {retryDate}</span>
            </div>
          );
        }

        return getTranslate('payment.single.retryactivenodate.label');
      case 'retries unsuccessful':
        return getTranslate('payment.single.retryunsuccesful.label');
      case 'retries canceled':
        return getTranslate('payment.single.retrycancelled.label');
      default:
        return '';
    }
  };

  getText = () => {
    const subscription = this.getCurrentPaymentData('subs');
    const registration = this.getCurrentPaymentData('init');
    const status = registration?.payment.recurring_status;

    if (status === statusList.notSet) {
      return {
        title: 'subscriptions.single.noRecurring.label',
        text: 'subscriptions.single.recurring.hint',
      };
    } else if (status === statusList.active && !subscription) {
      return {
        title: 'subscriptions.single.recurringNotSet.label',
        text: 'subscriptions.single.recurringNotSet.hint',
      };
    } else if (status === statusList.cancelled || status === 'canceled') {
      return {
        title: 'subscriptions.single.recurringCancelled.label',
        text: 'subscriptions.single.recurringCancelled.hint',
      };
    }

    return {
      title: '',
      text: '',
    };
  };

  openRetries = (operationId) => {
    const {
      pageUI: { currentPaymentType },
    } = this.state;

    this.changePageUI(
      {
        parentSelectedOperationId: operationId,
        isRetriesOpen: true,
      },
      () => {
        this.changePageUI({
          selectedOperationId:
            this.getInitialSelectedOperation(currentPaymentType),
        });
      }
    );
  };

  closeRetries = () => {
    const {
      pageUI: { parentSelectedOperationId },
    } = this.state;

    this.changePageUI({
      parentSelectedOperationId: '',
      isRetriesOpen: false,
      selectedOperationId: parentSelectedOperationId,
    });
  };

  openRetryCancelModal = () => {
    const { dispatch, getTranslate } = this.props;

    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          icon: (
            <div className='recurring-card__confirm-icon recurring-card__confirm-icon_danger'>
              <Icon name='im-Info-Hint' size={38} />
            </div>
          ),
          title: getTranslate('payment.single.modal.cancelRetries.header'),
          text: getTranslate('payment.single.modal.cancelRetries.text'),
          okButton: {
            text: getTranslate('payment.single.modal.cancelRetries.okButton'),
            status: 'danger',
          },
          cancelButton: {
            text: getTranslate(
              'payment.single.modal.cancelRetries.cancelButton'
            ),
            status: 'danger-outline',
          },
        },
        callback: (isAllowed) => {
          if (isAllowed) {
            this.stopRetries();
          }
        },
      })
    );
  };

  openRetrySuccessModal = () => {
    const { dispatch, getTranslate } = this.props;

    dispatch(
      openModal({
        modalId: 'Notification',
        content: {
          icon: (
            <div className='recurring-card__confirm-icon recurring-card__confirm-icon_success'>
              <Icon name='im-Check-mark' size={38} />
            </div>
          ),
          title: getTranslate('payment.single.modal.retriesStopped.header'),
          text: getTranslate('payment.single.modal.retriesStopped.text'),
          okButton: {
            text: getTranslate('payment.single.modal.response.button'),
            status: 'outline',
          },
        },
      })
    );
  };

  openRetryWarningModal = () => {
    const { dispatch, getTranslate } = this.props;

    dispatch(
      openModal({
        modalId: 'Notification',
        content: {
          icon: (
            <div className='recurring-card__confirm-icon recurring-card__confirm-icon_warning'>
              <Icon name='im-Error' size={38} />
            </div>
          ),
          title: getTranslate(
            'payment.single.modal.retriesStoppedWrong.header'
          ),
          text: getTranslate('payment.single.modal.retriesStoppedWrong.text'),
          okButton: {
            text: getTranslate('payment.single.modal.response.button'),
            status: 'outline',
          },
        },
      })
    );
  };

  stopRetries = async () => {
    const { recurring, fetchData } = this.props;
    const {
      pageUI: { selectedOperationId },
    } = this.state;

    if (!selectedOperationId) {
      return;
    }

    try {
      const { status } = await stopRecurringRetries(
        recurring.id,
        selectedOperationId
      );

      if (status === 'success') {
        fetchData();
        this.openRetrySuccessModal();
      }
    } catch (error) {
      this.openRetryWarningModal();
    }
  };

  renderTeaser = () => {
    const {
      recurring,
      getTranslate,
      isSettingsLoading,
      isLoading,
      isShowHistory,
      openSettingsForm,
      toggleHistory,
    } = this.props;

    const { paymentId, recurring_status } =
      recurring?.data?.registrationInfo?.payment || {};

    return (
      <Teaser
        isLoading={isLoading}
        title={getTranslate('subscriptions.subscriptionCard.name.header')}
        status={recurring_status}
        text={
          paymentId ? (
            <div className='payment-card__payment-id-with-copy-to-clipboard'>
              <span className='payment-card__payment-id'>{paymentId}</span>
              <CopyToClipboard
                text={paymentId}
                customClass='ui-copy-to-clipboard_color_white'
              />
            </div>
          ) : (
            ''
          )
        }>
        <Button
          text={getTranslate('payments.single.viewPaymentInfo.button')}
          disabled={isShowHistory}
          onClick={this.openPaymentInfo}
          status='light'
          size='normal'
          customClass='payment-card__mobile-button'
        />
        <Button
          text={getTranslate(
            'subscriptions.subscriptionCard.goToSettings.button'
          )}
          loading={isSettingsLoading}
          status='light'
          onClick={openSettingsForm}
          customClass='payment-card__teaser-button'
        />
        <Button
          text={getTranslate(
            isShowHistory
              ? 'subscriptions.subscriptionCard.backToOperations.button'
              : 'subscriptions.subscriptionCard.goToHistory'
          )}
          loading={isSettingsLoading}
          status='primary'
          onClick={toggleHistory}
          customClass='payment-card__teaser-button ui-button_status-primary_border-light'
        />
      </Teaser>
    );
  };

  renderContent = () => {
    const { historyList, isSettingsLoading, isShowHistory } = this.props;
    const { isOpenedPaymentInfo } = this.state;

    return (
      <div className='payment-card'>
        <div className='payment-card__inner'>
          <div
            className={classNames('payment-card__wrapper', {
              'recurring-settings': isShowHistory,
            })}>
            <div className='payment-card__item payment-card__item_prev'>
              {this.renderTeaser()}
            </div>

            {!isShowHistory ? (
              <div className='payment-card__item recurring-card__item_operations'>
                {this.renderRegistrations()}
                {this.renderOperations()}
              </div>
            ) : null}

            {isShowHistory ? (
              <div className='payment-card__item payment-card__item_operation-info'>
                <SettingsHistory
                  list={historyList}
                  isLoading={isSettingsLoading}
                />
              </div>
            ) : (
              <>
                <div className='payment-card__item payment-card__item_operation-info'>
                  {this.renderOperationInfo()}
                </div>
                {isOpenedPaymentInfo && (
                  <div className='payment-card__item payment-card__item_payment-info'>
                    {this.renderPaymentInfo()}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    );
  };

  renderOperationInfo = () => {
    const { isLoading, getTranslate } = this.props;
    const { pageUI } = this.state;
    const { currentPaymentType, parentSelectedOperationId } = pageUI;
    const data = this.getCurrentPaymentData(currentPaymentType);
    const { operations } = data || {};

    const noContentText = getTranslate(
      'subscriptions.single.recurringRetries.noInfo'
    );

    let currentOperation: any[] | null = null;

    if (operations && !isLoading) {
      currentOperation = this.getCurrentOperations(operations);
    }

    if (currentOperation && !currentOperation.length) {
      const selectedOperation = data?.operations.find(
        ({ operationId }) => operationId === parentSelectedOperationId
      );
      const { next_retry_date: retryDate } = selectedOperation;

      return (
        <div className='card payment-card__operations-empty'>
          <div className='card__container'>
            <div className='payment-card__operations-empty-inner'>
              <img src={noContent} alt={noContentText} />
              <h1 className='title-xl-bold'>{noContentText}</h1>

              {retryDate ? (
                <p className='text-m payment-card__operations-empty-text'>
                  {getTranslate(
                    'subscriptions.single.recurringRetries.nextTry'
                  )}
                  &nbsp;
                  <span className='bold'>{retryDate}</span>
                </p>
              ) : null}

              <Button
                status='outline'
                text={getTranslate(
                  'payments.single.backToMainOperation.button'
                )}
                onClick={this.closeRetries}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='recurring-card__operation utils-flex-column'>
        <div className='card recurring-card__operation-header'>
          {isLoading || !operations ? (
            <div className='recurring-card__loader-wrapper'>
              <Loader type='bung' />
            </div>
          ) : (
            this.renderOperationInfoHeader(currentOperation)
          )}
        </div>

        <div className='card recurring-card__operation-info'>
          {isLoading || !operations ? (
            <div className='recurring-card__loader-wrapper'>
              <Loader type='bung' />
            </div>
          ) : (
            <CustomScrollbar customHeight='100%'>
              <div className='recurring-card__operation-info-inner'>
                <OperationInfo
                  operation={this.getCurrentSelectedOperation(operations)}
                  transactionId={data && data.payment.transactionId}
                  pageUI={pageUI}
                  onChangePageUI={this.changePageUI}
                  modern
                />
              </div>
            </CustomScrollbar>
          )}
        </div>
      </div>
    );
  };

  renderOperationInfoHeader = (operations) => {
    const { isLoading, getTranslate, isEnabled, isDisabledByReason } =
      this.props;
    const {
      pageUI: { isRetriesOpen },
    } = this.state;

    const isCancelEnabled =
      isEnabled(Messages.SubscriptionPayment_RetryCancel) ||
      isDisabledByReason(
        Messages.SubscriptionPayment_RetryCancel,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );

    const selectedOperation = this.getCurrentSelectedOperation(operations);

    if (!isLoading && !selectedOperation) {
      return null;
    }

    const {
      operationId,
      next_retry_date: retryDate,
      mapped_recurring_retry_status: retryStatus,
    } = selectedOperation;

    return (
      <div className='recurring-card__operation-header-inner'>
        <div className='recurring-card__operation-header-wrapper'>
          <div>
            <div className='card__subtext recurring-card__operation-header-text'>
              {getTranslate('payments.single.operationCard.header')} ID
            </div>
            <div className='title-xl-bold recurring-card__operation-header-title'>
              {operationId}
              <CopyToClipboard text={operationId} />
            </div>
          </div>
          {retryStatus ? (
            <LabelStatus
              text={retryStatus}
              status={Mapper.getStatus(retryStatus)}
              customClass='recurring-card__operation-header-status'
            />
          ) : null}
        </div>

        {retryStatus ? (
          <div className='recurring-card__status-text'>
            {this.getStatusTooltip(retryStatus, retryDate)}
          </div>
        ) : null}

        {retryStatus ? (
          <div className='recurring-card__operation-header-actions'>
            {isRetriesOpen ? (
              <Button
                status='outline'
                text={getTranslate(
                  'payments.single.backToMainOperation.button'
                )}
                onClick={this.closeRetries}
              />
            ) : (
              <>
                <Button
                  status='primary'
                  text={getTranslate('payments.single.showretries.button')}
                  onClick={() => this.openRetries(operationId)}
                />
                <Button
                  status='danger-outline'
                  text={getTranslate('payments.single.cancelretries.button')}
                  onClick={this.openRetryCancelModal}
                  disabled={
                    !isCancelEnabled || retryStatus !== 'retries active'
                  }
                />
              </>
            )}
          </div>
        ) : null}
      </div>
    );
  };

  renderPaymentInfo = () => {
    const { isLoading } = this.props;
    const { pageUI } = this.state;
    const { currentPaymentType } = pageUI;
    const data = this.getCurrentPaymentData(currentPaymentType);

    return (
      <PaymentInfo
        theme='brand'
        isLoading={isLoading}
        closePaymentInfo={this.closePaymentInfo}
        data={data}
        pageUI={pageUI}
        onChangePageUI={this.changePageUI}
      />
    );
  };

  renderOperations = () => {
    const { getTranslate, isLoading } = this.props;
    const {
      pageUI: { operationOrder, isRetriesOpen, parentSelectedOperationId },
    } = this.state;
    const data = this.getCurrentPaymentData('subs');
    const { operations } = data || {};
    const paymentId = data?.payment.paymentId;
    const { title } = this.getText();

    const recurringTitle =
      paymentId || isLoading
        ? getTranslate('subscriptions.single.recurring.label')
        : getTranslate(title);
    const retryTitle = getTranslate(
      'subscriptions.single.recurringRetries.header'
    );

    return (
      <div
        className={classNames('recurring-card__payments', {
          'recurring-card__payments_empty': !operations && !isLoading,
        })}>
        <div className='card utils-height-100 utils-flex-column'>
          <div
            className={classNames(
              'card__header recurring-card__details-wrapper',
              {
                card__header_bordered: !isLoading && !!operations,
              }
            )}>
            <div className='recurring-card__details-block'>
              <div className='card__title'>
                {isRetriesOpen ? retryTitle : recurringTitle}
              </div>
              {paymentId ? (
                <div className='card__subtext recurring-card__details-text'>
                  <span>{getTranslate('subscriptions.single.Id.label')}</span>
                  <span className='utils-overflow-dots'>
                    {isRetriesOpen ? parentSelectedOperationId : paymentId}
                  </span>

                  {isRetriesOpen ? (
                    <CopyToClipboard
                      text={parentSelectedOperationId}
                      customClass='operation-item__copy'
                    />
                  ) : null}
                </div>
              ) : null}
            </div>

            <div className='recurring-card__details-actions'>
              {isRetriesOpen ? (
                <RoundButton
                  size={26}
                  icon='im-Arrow-left-Option-2'
                  iconSize={12}
                  onClick={this.closeRetries}
                />
              ) : null}

              {paymentId && (
                <RoundButton
                  size={26}
                  icon='im-Sort'
                  iconSize={12}
                  onClick={this.changeSort}
                  customClass={classNames({
                    'recurring-card__btn_inverse': operationOrder === 'asc',
                  })}
                />
              )}
            </div>
          </div>

          <div className='recurring-card__content'>
            {isLoading ? (
              <div className='recurring-card__loader-wrapper recurring-card__loader-wrapper_reset'>
                <Loader type='bung' />
              </div>
            ) : (
              this.renderOperationItem(operations)
            )}
          </div>
        </div>
      </div>
    );
  };

  renderOperationItem = (operations: any[]) => {
    const { getTranslate } = this.props;
    const {
      pageUI: { selectedOperationId, operationOrder },
    } = this.state;
    const { text } = this.getText();

    if (!operations) {
      return (
        <div className='recurring-card__operation-empty'>
          <div className='card__content'>
            <div className='recurring-card__text'>
              <div className='card__container'>{getTranslate(text)}</div>
            </div>
          </div>
        </div>
      );
    }

    const currentOperations = this.getCurrentOperations(operations);
    const sortedOperations = this.getSortedOperations(
      currentOperations,
      operationOrder
    );

    return (
      <CustomScrollbar>
        <div className='card__content'>
          {sortedOperations.length
            ? sortedOperations.map((operation) => {
                const { operationId: currentOperationId } = operation;

                return (
                  <OperationItem
                    key={currentOperationId}
                    data={operation}
                    isCurrent={currentOperationId === selectedOperationId}
                    onSelect={(selectedOperationId) =>
                      this.changePageUI({
                        selectedOperationId,
                        currentPaymentType: 'subs',
                      })
                    }
                    onStatusClick={(operationId) =>
                      this.openRetries(operationId)
                    }
                  />
                );
              })
            : null}
        </div>
      </CustomScrollbar>
    );
  };

  renderRegistrations = () => {
    const { getTranslate, isLoading } = this.props;
    const {
      pageUI: { selectedOperationId, initOperationsIsOpened },
    } = this.state;
    const data = this.getCurrentPaymentData('init');
    const { operations } = data || {};
    const paymentId = data?.payment.paymentId;

    return (
      <div
        className={classNames('recurring-card__registrations', {
          'recurring-card__registrations_opened': initOperationsIsOpened,
        })}>
        <div className='card utils-height-100 utils-flex-column'>
          <div
            className={classNames(
              'card__header utils-relative recurring-card__details-wrapper',
              {
                card__header_bordered:
                  !isLoading && operations?.length && initOperationsIsOpened,
              }
            )}>
            <div className='recurring-card__details-block'>
              <div className='card__title'>
                {getTranslate('subscriptions.single.init.label')}
              </div>
              <div className='card__subtext utils-overflow-dots'>
                {paymentId &&
                  `${getTranslate(
                    'subscriptions.single.Id.label'
                  )} ${paymentId}`}
              </div>
            </div>

            <div>
              {operations?.length && (
                <RoundButton
                  size={26}
                  icon='im-Arrow-down'
                  iconSize={10}
                  customClass={classNames({
                    'recurring-card__btn_inverse': initOperationsIsOpened,
                  })}
                  onClick={() =>
                    this.changePageUI({
                      initOperationsIsOpened: !initOperationsIsOpened,
                    })
                  }
                />
              )}
            </div>
          </div>

          <div className='recurring-card__content'>
            {isLoading ? (
              <div className='recurring-card__loader-wrapper recurring-card__loader-wrapper_reset'>
                <Loader type='bung' />
              </div>
            ) : initOperationsIsOpened ? (
              <CustomScrollbar>
                <div className='card__content'>
                  {operations?.map((operation) => {
                    const { operationId: currentOperationId } = operation;

                    return (
                      <OperationItem
                        key={currentOperationId}
                        data={operation}
                        isCurrent={currentOperationId === selectedOperationId}
                        onSelect={(selectedOperationId) =>
                          this.changePageUI({
                            selectedOperationId,
                            currentPaymentType: 'init',
                          })
                        }
                      />
                    );
                  })}
                </div>
              </CustomScrollbar>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { recurring, getTranslate } = this.props;

    return (
      <PageTemplate.Container customClass='payment-card-page'>
        <div className='payment-card-area'>
          <Animation>
            <div className='payment-card-container'>
              {recurring?.data?.error ? (
                <NoContent text={getTranslate('common.noMatches.text')} />
              ) : (
                this.renderContent()
              )}
            </div>
          </Animation>
        </div>
      </PageTemplate.Container>
    );
  }
}

export default connect()(addTranslation(addPermissions(RecurringCard)));
