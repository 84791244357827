import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, throttle } from 'lodash-es';
import classNames from 'classnames';
import Tooltip from 'react-tooltip';

import { StoreProps } from 'store';
import { openModal } from 'actions/modal';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import PaymentPageUI from 'types/PaymentPageUI';
import PaymentItemType from 'types/savedEntity/SavedPayment';
import DateHelpers from 'helpers/Date';
import Mapper from 'helpers/Mapper';
import Operations from 'helpers/Operations';
import Messages from 'constants/rpcTypes';
import breakpoints from 'constants/breakpoints';
import permissionReasons from 'constants/permissionReasons';
import { stopRecurringRetries } from 'api/recurring';

import Animation from 'components/ui/animation';
import PageTemplate from 'components/pageTemplate/PageTemplate';
import NoContent from 'components/ui/noContent';
import Teaser from 'components/ui/teaser';
import PaymentActions from 'components/paymentCard/paymentActions/PaymentActionsContainer';
import { Config } from 'components/paymentCard/PaymentCardContainer';
import OperationItem from 'components/paymentCard/operationItem';
import CustomScrollbar from 'components/ui/customScrollbar';
import CopyToClipboard from 'components/ui/copyToClipboard';
import OperationInfo from 'components/paymentCard/operationInfo';
import AddBlackList from 'components/paymentCard/panelBlackList/AddBlackListContainer';
import ButtonReference from 'components/paymentCard/buttonReference';
import CreateSupportTicketButton from 'components/createSupportTicketButton/CreateSupportTicketButton';
import PaymentInfo from 'components/paymentCard/paymentInfo';
import Button from 'components/ui/button';
import Loader from 'components/ui/loader';
import { Tab, TabsContainer } from 'components/ui/tabs';
import { CallbackInfo } from '../callbackInfo';
import RoundButton from 'components/ui/roundButton';
import LabelStatus from 'components/ui/labelStatus';
import Icon from 'components/ui/icon';
import Panel from 'components/ui/panel';
import noContent from 'images/no-content.png';
import './paymentCard.scss';

type orderType = 'asc' | 'desc';

interface Props extends IntlProps, WithPermissions, StoreProps {
  payment?: PaymentItemType;
  config?: Config;
  projectId?: string;
  isLoading: boolean;
  syncPaymentUI: (id: string, ui: PaymentPageUI) => void;
  fetchData: () => void;
}

interface State {
  pageUI: PaymentPageUI;
  canRenderActions: boolean;
  isOpenedPaymentInfo: boolean;
  isActionsOpened: boolean;
}

class PaymentCard extends Component<Props, State> {
  constructor(props) {
    super(props);
    this.state = {
      pageUI: { ...props.payment?.ui },
      canRenderActions: true,
      isOpenedPaymentInfo: this.setPaymentInfoState(),
      isActionsOpened: false,
    };
    this.handleResize = throttle(this.handleResize, 500);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    const { payment, syncPaymentUI } = this.props;
    const { payment: prevPayment } = prevProps;
    const transactionId = payment?.data?.payment?.transactionId;
    const prevTransactionId = prevPayment?.data?.payment?.transactionId;

    // check for late props updating
    if (!payment || !prevPayment) {
      return;
    }

    if (transactionId !== prevTransactionId) {
      this.setState({ canRenderActions: false }, () => {
        this.setState({ canRenderActions: true });
      });
    }

    // sync with redux store data if user move between payments cards
    if (prevPayment.id !== payment.id) {
      syncPaymentUI(prevPayment.id, prevState.pageUI);
    }

    // set data to local state if props data changed
    if (!isEqual(prevPayment, payment)) {
      Tooltip.rebuild();

      if (payment?.ui) {
        let pageUI = { ...payment.ui } as PaymentPageUI;

        // if payment data updated but it doesnt have initial selectedId and retry operations haven't been opened
        if (!pageUI.selectedOperationId && !pageUI.isRetriesOpen) {
          pageUI = {
            ...pageUI,
            selectedOperationId: payment?.data
              ? this.getInitialSelectedOperation()
              : '',
          };
        }

        this.setState({
          pageUI,
        });
      }
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  changePageUI = (newPageUI: Partial<PaymentPageUI>, callback?: () => void) => {
    this.setState(
      (prevState) => ({
        ...prevState,
        pageUI: {
          ...prevState.pageUI,
          ...newPageUI,
        },
      }),
      callback
    );
  };

  openPaymentInfo = () => {
    this.setState({ isOpenedPaymentInfo: true });
  };

  closePaymentInfo = () => {
    this.setState({ isOpenedPaymentInfo: false });
  };

  handleResize = () => {
    if (document.body.clientWidth < breakpoints.commonTabletL) {
      this.closePaymentInfo();
    } else {
      this.openPaymentInfo();
    }
  };

  setPaymentInfoState = () => {
    return document.body.clientWidth >= breakpoints.commonTabletL;
  };

  changeSort = () => {
    const {
      pageUI: { operationOrder },
    } = this.state;

    this.changePageUI({
      operationOrder: operationOrder === 'desc' ? 'asc' : 'desc',
    });
  };

  openRetryCancelModal = () => {
    const { dispatch, getTranslate } = this.props;

    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          icon: (
            <div className='recurring-card__confirm-icon recurring-card__confirm-icon_danger'>
              <Icon name='im-Info-Hint' size={38} />
            </div>
          ),
          title: getTranslate('payment.single.modal.cancelRetries.header'),
          text: getTranslate('payment.single.modal.cancelRetries.text'),
          okButton: {
            text: getTranslate('payment.single.modal.cancelRetries.okButton'),
            status: 'danger',
          },
          cancelButton: {
            text: getTranslate(
              'payment.single.modal.cancelRetries.cancelButton'
            ),
            status: 'danger-outline',
          },
        },
        callback: (isAllowed) => {
          if (isAllowed) {
            this.stopRetries();
          }
        },
      })
    );
  };

  openRetryWarningModal = () => {
    const { dispatch, getTranslate } = this.props;

    dispatch(
      openModal({
        modalId: 'Notification',
        content: {
          icon: (
            <div className='recurring-card__confirm-icon recurring-card__confirm-icon_warning'>
              <Icon name='im-Error' size={38} />
            </div>
          ),
          title: getTranslate(
            'payment.single.modal.retriesStoppedWrong.header'
          ),
          text: getTranslate('payment.single.modal.retriesStoppedWrong.text'),
          okButton: {
            text: getTranslate('payment.single.modal.response.button'),
            status: 'outline',
          },
        },
      })
    );
  };

  openRetrySuccessModal = () => {
    const { dispatch, getTranslate } = this.props;

    dispatch(
      openModal({
        modalId: 'Notification',
        content: {
          icon: (
            <div className='recurring-card__confirm-icon recurring-card__confirm-icon_success'>
              <Icon name='im-Check-mark' size={38} />
            </div>
          ),
          title: getTranslate('payment.single.modal.retriesStopped.header'),
          text: getTranslate('payment.single.modal.retriesStopped.text'),
          okButton: {
            text: getTranslate('payment.single.modal.response.button'),
            status: 'outline',
          },
        },
      })
    );
  };

  stopRetries = async () => {
    const { payment, fetchData } = this.props;
    const {
      pageUI: { selectedOperationId },
    } = this.state;

    if (!selectedOperationId || !payment?.id) {
      return;
    }

    try {
      const { status } = await stopRecurringRetries(
        payment.id,
        selectedOperationId
      );

      if (status === 'success') {
        fetchData();
        this.openRetrySuccessModal();
      }
    } catch (error) {
      this.openRetryWarningModal();
    }
  };

  getStatusTooltip = (status: string, retryDate: string) => {
    const { getTranslate } = this.props;

    switch (status) {
      case 'retry in progress':
        return getTranslate('payment.single.retryprogress.label');
      case 'retries active':
        if (retryDate) {
          return (
            <div>
              {getTranslate('payment.single.retryactivedate.label')}
              <span className='bold'> {retryDate}</span>
            </div>
          );
        }

        return getTranslate('payment.single.retryactivenodate.label');
      case 'retries unsuccessful':
        return getTranslate('payment.single.retryunsuccesful.label');
      case 'retries canceled':
        return getTranslate('payment.single.retrycancelled.label');
      default:
        return '';
    }
  };

  getInitialSelectedOperation = () => {
    // get initial selectedId for mark current operation
    const { payment } = this.props;
    const { operations } = payment?.data;

    if (!operations) {
      return '';
    }

    const currentOperations = this.getCurrentOperations(operations);
    const { operationId } =
      this.getCurrentSelectedOperation(currentOperations) || {};

    return operationId ?? '';
  };

  getSelectedOperation = (operations) => {
    // get operation by id or first one by default
    const {
      pageUI: { selectedOperationId },
    } = this.state;

    return (
      operations.find(
        ({ operationId }) => selectedOperationId === operationId
      ) || operations[0]
    );
  };

  getCurrentOperations = (operations: any[]) => {
    // check if retries is open and decide to show operations for retry
    const {
      pageUI: { parentSelectedOperationId, isRetriesOpen },
    } = this.state;
    let copyOperations = [...operations];

    if (isRetriesOpen) {
      const operation = copyOperations.find(
        ({ operationId }) => operationId === parentSelectedOperationId
      );

      if (operation) {
        const { recurring_retry_operations } = operation;
        copyOperations = [...recurring_retry_operations];
      }
    }

    return copyOperations;
  };

  getSortedOperations = (operations: any[], order: orderType) => {
    // sort operations by dates
    return operations.sort((o1, o2) => {
      const date1 = +DateHelpers.createDate(
        o1.creationDate,
        'datetime'
      ).toDate();
      const date2 = +DateHelpers.createDate(
        o2.creationDate,
        'datetime'
      ).toDate();

      if (order === 'asc') {
        return date1 - date2;
      }

      return date2 - date1;
    });
  };

  getCurrentSelectedOperation = (currentOperations) => {
    // sort operations and get selected operation
    const {
      pageUI: { operationOrder },
    } = this.state;

    const sortedOperations = this.getSortedOperations(
      currentOperations,
      operationOrder
    );

    return this.getSelectedOperation(sortedOperations);
  };

  closeRetries = () => {
    const {
      pageUI: { parentSelectedOperationId },
    } = this.state;

    this.changePageUI({
      parentSelectedOperationId: '',
      isRetriesOpen: false,
      selectedOperationId: parentSelectedOperationId,
    });
  };

  openRetries = (operationId) => {
    this.changePageUI(
      {
        parentSelectedOperationId: operationId,
        isRetriesOpen: true,
      },
      () => {
        this.changePageUI({
          selectedOperationId: this.getInitialSelectedOperation(),
        });
      }
    );
  };

  renderOperationsList = () => {
    const { payment, isLoading, getTranslate } = this.props;
    const {
      pageUI: { isRetriesOpen, parentSelectedOperationId, operationOrder },
    } = this.state;
    const { operations } = payment?.data || {};
    const operationsCount = !isLoading && operations ? operations.length : '';
    const operationsDuration =
      !isLoading && operations ? Operations.getDuration(operations || []) : '';
    const paymentTitle = getTranslate('payments.data.paymentOperations.header');
    const operationsCountText = getTranslate('payments.single.operation', {
      operationsCount,
    });
    const operationDurationText = getTranslate('payments.single.duration', {
      operationsDuration,
    });
    const paymentOperationCount = `${operationsCountText} | ${operationDurationText}`;
    const retryTitle = getTranslate(
      'subscriptions.single.recurringRetries.header'
    );

    return (
      <div className='payment-card__operations card utils-flex-column'>
        <div className='card__header payment-card__details-wrapper'>
          <div className='payment-card__details-block'>
            <div className='card__title'>
              {isRetriesOpen ? retryTitle : paymentTitle}
            </div>

            {operationsDuration ? (
              <div className='card__subtext payment-card__details-text'>
                <span>{getTranslate('subscriptions.single.Id.label')}</span>
                <span className='utils-overflow-dots'>
                  {isRetriesOpen
                    ? parentSelectedOperationId
                    : paymentOperationCount}
                </span>

                {isRetriesOpen ? (
                  <CopyToClipboard
                    text={parentSelectedOperationId}
                    customClass='operation-item__copy'
                  />
                ) : null}
              </div>
            ) : null}
          </div>

          <div className='payment-card__details-actions'>
            {isRetriesOpen ? (
              <RoundButton
                size={26}
                icon='im-Arrow-left-Option-2'
                iconSize={12}
                onClick={this.closeRetries}
              />
            ) : null}

            {operations?.length ? (
              <RoundButton
                size={26}
                icon='im-Sort'
                iconSize={12}
                onClick={this.changeSort}
                customClass={classNames({
                  'payment-card__btn_inverse': operationOrder === 'asc',
                })}
              />
            ) : null}
          </div>
        </div>

        <div className='payment-card__content'>
          {isLoading || !operations ? (
            <div className='payment-card__loader-wrapper payment-card__loader-wrapper_reset'>
              <Loader type='bung' />
            </div>
          ) : (
            this.renderOperationItem(operations)
          )}
        </div>
      </div>
    );
  };

  renderOperationItem = (operations: any[]) => {
    const {
      pageUI: { selectedOperationId, operationOrder, operationsScrollTop },
    } = this.state;

    if (!operations) {
      return null;
    }

    const currentOperations = this.getCurrentOperations(operations);
    const sortedOperations = this.getSortedOperations(
      currentOperations,
      operationOrder
    );

    return (
      <CustomScrollbar
        initialScrollTop={operationsScrollTop}
        needResetScrollTop
        onScrollStop={({ operationsScrollTop }) =>
          this.changePageUI({ operationsScrollTop })
        }>
        <div className='card__content payment-card__operations-list'>
          {sortedOperations.length
            ? sortedOperations.map((operation) => {
                const { operationId: currentOperationId } = operation;

                return (
                  <OperationItem
                    key={currentOperationId}
                    data={operation}
                    isCurrent={currentOperationId === selectedOperationId}
                    onSelect={(selectedOperationId) =>
                      this.changePageUI({
                        selectedOperationId,
                      })
                    }
                    onStatusClick={(operationId) =>
                      this.openRetries(operationId)
                    }
                  />
                );
              })
            : null}
        </div>
      </CustomScrollbar>
    );
  };

  renderOperationInfo = () => {
    const { payment, isLoading, getTranslate } = this.props;
    const { operations } = payment?.data || {};
    const {
      pageUI: { parentSelectedOperationId },
    } = this.state;

    const noContentText = getTranslate(
      'subscriptions.single.recurringRetries.noInfo'
    );

    let currentOperation: any[] | null = null;

    if (operations && !isLoading) {
      currentOperation = this.getCurrentOperations(operations);
    }

    if (currentOperation && !currentOperation.length) {
      const selectedOperation = operations.find(
        ({ operationId }) => operationId === parentSelectedOperationId
      );
      const { next_retry_date: retryDate } = selectedOperation;

      return (
        <div className='card payment-card__operations-empty'>
          <div className='card__container'>
            <div className='payment-card__operations-empty-inner'>
              <img src={noContent} alt={noContentText} />
              <h1 className='title-xl-bold'>{noContentText}</h1>

              {retryDate ? (
                <p className='text-m payment-card__operations-empty-text'>
                  {getTranslate(
                    'subscriptions.single.recurringRetries.nextTry'
                  )}
                  &nbsp;
                  <span className='bold'>{retryDate}</span>
                </p>
              ) : null}

              <Button
                status='outline'
                text={getTranslate(
                  'payments.single.backToMainOperation.button'
                )}
                onClick={this.closeRetries}
              />
            </div>
          </div>
        </div>
      );
    }

    return (
      <div className='payment-card__operation utils-flex-column'>
        <div className='card payment-card__operation-header'>
          {isLoading || !operations ? (
            <div className='payment-card__loader-wrapper'>
              <Loader type='bung' />
            </div>
          ) : (
            this.renderOperationInfoHeader(currentOperation)
          )}
        </div>

        <div className='card payment-card__content'>
          {isLoading || !operations ? (
            <div className='payment-card__loader-wrapper'>
              <Loader type='bung' />
            </div>
          ) : (
            this.renderOperationInfoTabs(currentOperation)
          )}
        </div>
      </div>
    );
  };

  renderOperationInfoHeader = (operations) => {
    const { isLoading, getTranslate } = this.props;

    const selectedOperation = this.getCurrentSelectedOperation(operations);

    if (!isLoading && !selectedOperation) {
      return null;
    }

    const {
      operationId,
      next_retry_date: retryDate,
      mapped_recurring_retry_status: retryStatus,
    } = selectedOperation;

    return (
      <div className='payment-card__operation-header-inner'>
        <div className='payment-card__operation-header-wrapper'>
          <div>
            <div className='card__subtext payment-card__operation-header-text'>
              {getTranslate('payments.single.operationCard.header')} ID
            </div>
            <div className='title-xl-bold payment-card__operation-header-title'>
              {operationId}
              <CopyToClipboard text={operationId} />
            </div>
          </div>
          {retryStatus ? (
            <LabelStatus
              text={retryStatus}
              status={Mapper.getStatus(retryStatus)}
              customClass='payment-card__operation-header-status'
            />
          ) : null}
        </div>
        {retryStatus ? (
          <div className='payment-card__status-tooltip-text'>
            {this.getStatusTooltip(retryStatus, retryDate)}
          </div>
        ) : null}
        {this.renderOperationInfoActions(operations)}
      </div>
    );
  };

  renderOperationInfoTabs = (operations) => {
    const { payment, isLoading, getTranslate } = this.props;
    const { pageUI } = this.state;
    const { transactionId, showVatFields } = payment?.data?.payment || {};
    const selectedOperation = this.getCurrentSelectedOperation(operations);

    if (!isLoading && !selectedOperation) {
      return null;
    }

    const { operationId, callback, availableActions } = selectedOperation;
    const hasCallback = callback?.callbackBody;
    const resendCallbackEnabled = availableActions?.callbackResend?.isEnabled;

    return (
      <TabsContainer
        theme='brand'
        customClass='payment-card__operation-tabs-wrapper'>
        <Tab title={getTranslate('payments.single.operationInfo.header')}>
          <CustomScrollbar
            customHeight='100%'
            initialScrollTop={pageUI.operationInfoScrollTop}
            needResetScrollTop
            onScrollStop={({ operationInfoScrollTop }) =>
              this.changePageUI({ operationInfoScrollTop })
            }>
            <div className='payment-card__operation-info-wrapper'>
              <OperationInfo
                transactionId={transactionId}
                operation={selectedOperation}
                pageUI={pageUI}
                onChangePageUI={this.changePageUI}
                showVatFields={showVatFields}
                modern
              />
            </div>
          </CustomScrollbar>
        </Tab>
        {hasCallback ? (
          <Tab title={getTranslate('payments.single.lastcallback.header')}>
            <CallbackInfo
              transactionId={transactionId}
              operationId={operationId}
              callback={callback}
              resendEnabled={resendCallbackEnabled}
            />
          </Tab>
        ) : null}
      </TabsContainer>
    );
  };

  renderOperationInfoActions = (operations) => {
    const { config, payment, isEnabled, isDisabledByReason, getTranslate } =
      this.props;
    const {
      canRenderActions,
      pageUI: { isRetriesOpen },
      isActionsOpened,
    } = this.state;
    const { transactionId, projectId } = payment?.data?.payment || {};
    const { isAddToList } = config || {};

    const {
      operationId,
      operationType,
      availableActions,
      mapped_recurring_retry_status: retryStatus,
    } = this.getCurrentSelectedOperation(operations);
    const referenceButtonEnabled =
      availableActions?.operationReferenceOrder?.isEnabled;
    const isEnabledToCreateTicket =
      isEnabled(Messages.PaymentIssue_PerformAction) ||
      isDisabledByReason(
        Messages.PaymentIssue_PerformAction,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );
    const isCancelEnabled =
      isEnabled(Messages.SubscriptionPayment_RetryCancel) ||
      isDisabledByReason(
        Messages.SubscriptionPayment_RetryCancel,
        permissionReasons.REASON_IS_NOT_AVAILABLE_FOR_SUPPORT
      );

    const actions = (
      <>
        {referenceButtonEnabled ? (
          <ButtonReference
            transactionId={transactionId}
            operationId={operationId}
          />
        ) : null}

        {isEnabledToCreateTicket ? (
          <CreateSupportTicketButton
            operationId={operationId}
            transactionId={transactionId}
          />
        ) : null}

        {isAddToList ? (
          <AddBlackList
            transactionId={transactionId}
            operationId={operationId}
          />
        ) : null}

        {canRenderActions && operationType === 'recurring' ? (
          <PaymentActions
            isNeedPing={false}
            operationId={operationId}
            transactionId={transactionId}
            projectId={projectId}
            refundTooltip={getTranslate(
              'payments.data.operationRefund.info.tooltip'
            )}
            customClass='payment-actions_card'
            buttonTheme='primary'
          />
        ) : null}
      </>
    );

    return (
      <div className='payment-card__operation-header-actions'>
        {retryStatus ? (
          <>
            {isRetriesOpen ? (
              <Button
                status='outline'
                text={getTranslate(
                  'payments.single.backToMainOperation.button'
                )}
                onClick={this.closeRetries}
              />
            ) : (
              <>
                <Button
                  status='primary'
                  text={getTranslate('payments.single.showretries.button')}
                  onClick={() => this.openRetries(operationId)}
                />
                <Button
                  status='danger-outline'
                  text={getTranslate('payments.single.cancelretries.button')}
                  onClick={this.openRetryCancelModal}
                  disabled={
                    !isCancelEnabled || retryStatus !== 'retries active'
                  }
                />
              </>
            )}

            <div className='actions-hide'>
              <Button
                icon='im-Dots'
                customClass='actions-hide-button'
                onClick={() => {
                  this.setState({
                    isActionsOpened: true,
                  });
                }}
              />

              <Panel
                isOpened={isActionsOpened}
                renderCloseControl={false}
                contentStyle={{
                  borderRadius: 12,
                  padding: 12,
                  boxShadow: '0px 5px 40px 0px rgba(0, 0, 0, 0.15)',
                  overflow: 'unset',
                }}
                onClose={() =>
                  this.setState({
                    isActionsOpened: false,
                  })
                }
                customClass='actions-hide-panel'>
                <div className='actions-hide-panel__content'>{actions}</div>
              </Panel>
            </div>
          </>
        ) : (
          actions
        )}
      </div>
    );
  };

  renderPaymentInfo = () => {
    const { config, payment, isLoading } = this.props;
    const { pageUI } = this.state;
    const { isManagePayment, isCustomerInfo, isRemittanceRecipientAccount } =
      config || {};

    return (
      <PaymentInfo
        theme='brand'
        isLoading={isLoading}
        closePaymentInfo={this.closePaymentInfo}
        data={payment?.data}
        pageUI={pageUI}
        onChangePageUI={this.changePageUI}
        isManagePayment={isManagePayment}
        isCustomerInfo={isCustomerInfo}
        isRemittanceRecipientAccount={isRemittanceRecipientAccount}
      />
    );
  };

  renderTeaser = () => {
    const { canRenderActions } = this.state;
    const { payment, config, isLoading, getTranslate } = this.props;
    const { paymentId, transactionId, projectId, paymentStatus } =
      payment?.data?.payment || {};
    const { isManagePayment } = config || {};

    return (
      <Teaser
        title={getTranslate('payments.data.payment.header')}
        text={
          paymentId ? (
            <div className='payment-card__payment-id-with-copy-to-clipboard'>
              <span className='payment-card__payment-id'>{paymentId}</span>
              <CopyToClipboard
                text={paymentId}
                customClass='ui-copy-to-clipboard_color_white'
              />
            </div>
          ) : (
            ''
          )
        }
        status={paymentStatus}
        isLoading={isLoading}>
        <Button
          text={getTranslate('payments.single.viewPaymentInfo.button')}
          onClick={this.openPaymentInfo}
          status='outline-border'
          size='normal'
          customClass='payment-card__mobile-button'
        />
        {isManagePayment && canRenderActions ? (
          <PaymentActions
            transactionId={transactionId}
            projectId={projectId}
            buttonTheme='light'
            customClass='payment-actions_card'
          />
        ) : null}
      </Teaser>
    );
  };

  renderContent = () => {
    const { isOpenedPaymentInfo } = this.state;

    return (
      <div className='payment-card'>
        <div className='payment-card__inner'>
          <div className='payment-card__wrapper'>
            <div className='payment-card__item payment-card__item_prev'>
              {this.renderTeaser()}
            </div>
            <div className='payment-card__item payment-card__item_operations'>
              {this.renderOperationsList()}
            </div>
            <div className='payment-card__item payment-card__item_operation-info'>
              {this.renderOperationInfo()}
            </div>

            {isOpenedPaymentInfo ? (
              <div className='payment-card__item payment-card__item_payment-info'>
                {this.renderPaymentInfo()}
              </div>
            ) : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { payment, getTranslate } = this.props;

    return (
      <PageTemplate.Container customClass='payment-card-page'>
        <div className='payment-card-area'>
          <Animation>
            <div className='payment-card-container'>
              {payment?.data?.error ? (
                <NoContent
                  text={getTranslate(
                    'payments.single.paymentInfo.noPaymentDetails.text'
                  )}
                />
              ) : (
                this.renderContent()
              )}
            </div>
          </Animation>
        </div>
      </PageTemplate.Container>
    );
  }
}

export default connect()(addTranslation(addPermissions(PaymentCard)));
