import getLocalizedText from 'helpers/getLocalizedText';
import Utils from 'helpers/Utils';
import React, { ReactNode } from 'react';
import { FilterTypes } from 'constants/FilterTypes';
import Input from 'components/ui/input/Input';
import InputAmount from 'components/ui/inputAmount';
import RadioGroup from 'components/ui/radioGroup';
import Radio from 'components/ui/radio';
import CustomSelect from 'components/ui/customSelect/CustomSelect';
import fieldClassNames from 'classnames';
import Switch from 'components/ui/switch';
import InputDate from 'components/ui/inputDate/InputDate';
import DropZone from 'components/ui/dropzone/Dropzone';
import { FormField as FormFieldType } from 'components/formFields/types';
import { AnyObject } from 'types/Common';

interface Props {
  field: FormFieldType;
  options: {
    fieldsValues: any;
    dictionaries?: { [key: string]: any };
    fieldsState?: AnyObject;
    hiddenFields?: AnyObject;
    validationErrors: any;
    onChange?: (id, value, category?) => void;
    loadMore?: (dictionaryId: string) => void;
    isLoading?: boolean;
    formClassName: string;
  };
}

/* eslint-disable complexity*/
const FormField: React.FC<Props> = ({ field, options }) => {
  const {
    fieldsValues,
    dictionaries,
    fieldsState,
    hiddenFields,
    validationErrors,
    onChange,
    loadMore,
    isLoading,
  } = options;
  const getTranslate = getLocalizedText;

  const { id, label, placeholder, tooltip } = field;

  if (hiddenFields !== undefined && hiddenFields[id]) return null;

  const value = fieldsValues[id];
  const error = validationErrors[id];
  const isDisabled =
    isLoading ||
    (fieldsState !== undefined &&
      (Utils.hasProp(fieldsState, id) ? !fieldsState[id] : false));
  let fieldToRender: null | ReactNode;
  switch (field.fieldType) {
    case FilterTypes.text:
      fieldToRender = (
        <Input
          key={id}
          id={id}
          label={getTranslate(label)}
          placeholder={placeholder || ''}
          tooltip={getTranslate(tooltip || '')}
          value={value}
          onChange={(e) => onChange?.(id, e.target.value)}
          error={error}
          disabled={isDisabled}
          maxLength={field.maxLength}
          modern
          customClass={`${options.formClassName}__field`}
          clearButton={field.clearButton}
        />
      );
      break;
    case FilterTypes.numbers:
      fieldToRender = (
        <Input
          key={id}
          id={id}
          label={getTranslate(label)}
          placeholder={placeholder || ''}
          tooltip={getTranslate(tooltip || '')}
          value={value}
          onChange={(e) => onChange?.(id, e.target.value)}
          error={error}
          disabled={isDisabled}
          maxLength={field.maxLength}
          modern
          customClass={`${options.formClassName}__field`}
          clearButton={field.clearButton}
          cleaveOptions={{ numericOnly: true }}
        />
      );
      break;
    case 'textarea':
      fieldToRender = (
        <Input
          key={id}
          id={id}
          type='textarea'
          label={getTranslate(label)}
          placeholder={placeholder || ''}
          tooltip={getTranslate(tooltip || '')}
          value={value}
          onChange={(e) => onChange?.(id, e.target.value)}
          error={error}
          disabled={isDisabled}
          maxLength={field.maxLength}
          modern
          customClass={`${options.formClassName}__field`}
        />
      );
      break;
    case FilterTypes.amount:
      fieldToRender = (
        <InputAmount
          key={id}
          id={id}
          label={getTranslate(label)}
          placeholder={getTranslate(placeholder || '')}
          tooltip={getTranslate(tooltip || '')}
          value={value}
          disabled={isDisabled}
          onChange={(e) => onChange?.(id, e.target.value)}
          error={error}
          modern
          customClass={`${options.formClassName}__field`}
        />
      );
      break;

    case FilterTypes.radioList:
      fieldToRender = (
        <RadioGroup
          id={id}
          checkedId={value}
          onChange={(newValue) => {
            onChange?.(id, newValue);
          }}
          label={getTranslate(label)}
          error={error}
          customClass={`${options.formClassName}__field ui-radio-group_row`}>
          {field.list.map((item) => {
            return (
              <Radio
                key={item.id}
                id={item.id}
                text={getTranslate(item.text)}
                infoIcon={getTranslate(item.tooltip)}
              />
            );
          })}
        </RadioGroup>
      );
      break;

    case FilterTypes.select: {
      const dictionaryId =
        typeof field.dictionaryId === 'object'
          ? field.dictionaryId?.name
          : field.dictionaryId;
      const list = dictionaryId
        ? dictionaries?.[dictionaryId]?.list
        : field.list;
      const isPaymentMethod =
        dictionaryId?.toLowerCase().includes('paymentmethod') ||
        dictionaryId?.toLowerCase().includes('paymentpagemethod');

      fieldToRender = (
        <CustomSelect
          id={id}
          key={id}
          label={getTranslate(label)}
          tooltip={getTranslate(tooltip || '')}
          options={list}
          isSearchable
          isDisabled={isDisabled}
          isLoadMore={dictionaryId && dictionaries?.[dictionaryId]?.hasMoreRows}
          loadMore={dictionaryId ? () => loadMore?.(dictionaryId) : undefined}
          isLoading={dictionaryId && dictionaries?.[dictionaryId]?.isLoading}
          value={list?.find((item) => item.value === value) || null}
          onChange={(item) => {
            onChange?.(id, item?.value);
          }}
          error={error}
          modern
          customClass={fieldClassNames(
            `${options.formClassName}__field ui-select_no-min-width`,
            { 'ui-select_payment-method': isPaymentMethod }
          )}
        />
      );
      break;
    }
    case 'switch':
      fieldToRender = (
        <Switch
          id={id}
          text={getTranslate(label)}
          checked={value}
          onChange={(event) => onChange?.(id, event.target.checked)}
          disabled={isDisabled}
          customClass={`${options.formClassName}__field`}
        />
      );
      break;
    case FilterTypes.dateTime:
      fieldToRender = (
        <InputDate
          id={id}
          selectedDate={value}
          maxDate={field.maxDate || ''}
          minDate={field.minDate || ''}
          onChange={(date) => onChange?.(id, date)}
          customClass={`${options.formClassName}__field`}
          title={getTranslate(label)}
          error={error}
          isDisabled={isDisabled}
          containerSelector={`.${options.formClassName}__content`}
          modern
          closeAfterSelect
        />
      );
      break;
    case 'fileLoader':
      fieldToRender = (
        <DropZone
          noClick={true}
          file={value}
          showText={false}
          onDrop={(files) => {
            files[0] && onChange?.(id, files[0]);
          }}
          label={field.label}
          onDeleteFile={field.onDeleteFile}
          errorMessage={error}
        />
      );
      break;
    case 'title':
      fieldToRender = (
        <div className={`${options.formClassName}__col-title`}>
          {getTranslate(field.label)}
        </div>
      );
      break;
    case 'infoText':
      fieldToRender = (
        <div className={`${options.formClassName}__info-text`}>
          {getTranslate(field.label)}
        </div>
      );
      break;
    default:
      return null;
  }

  return (
    <div className={`${options.formClassName}__item`} key={id}>
      {fieldToRender}
      {field.subText && (
        <div className={`${options.formClassName}__subtext`}>
          {getTranslate(field.subText)}
        </div>
      )}
    </div>
  );
};
export default FormField;
