import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { isEmpty } from 'lodash-es';
import {
  createAdditionalFeature,
  updateAdditionalFeature,
  loadFeaturePictures,
} from 'api/overview';

import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { addListeners } from 'decorators/addListeners';
import ModalTitle from 'components/modal/components/ModalTitle';
import ModalFooter from 'components/modal/components/ModalFooter';
import Button from 'components/ui/button';
import ModalContent from 'components/modal/components/ModalContent';
import FormField from 'components/formField';
import formStateFactory from 'components/formFields/formStateFactory';
import Loader from 'components/ui/loader';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import Messages from 'constants/rpcTypes';
import fieldsConfig from './fieldsConfig';
import { StoreProps } from 'store';
import {
  addAdditionalFeature,
  updateStoredAdditionalFeature,
} from 'actions/additionalFeature';
import { closeModal } from 'actions/modal';
import { Feature } from 'reducers/additionalFeatures';
import './addAdditionalFeature.scss';

interface Props {
  content?: Feature;
}

interface State {
  isLoading: boolean;
  validationErrors: Feature;
}

@addListeners([
  Messages.AdditionalFeature_Create,
  Messages.AdditionalFeature_Update,
])
class AddAdditionalFeature extends PureComponent<
  Props & IntlProps & StoreProps,
  State & Feature
> {
  constructor(props) {
    super(props);
    this.state = {
      ...formStateFactory(fieldsConfig),
      validationErrors: {},
      isLoading: false,
    };
  }
  componentDidMount() {
    if (this.props.content && !isEmpty(this.props.content)) {
      this.setState({
        languageCode: this.props.content.languageCode,
        category: this.props.content.category,
        title: this.props.content.title,
        description: this.props.content.description,
        iconPath: this.props.content.iconPath,
        picturePath: this.props.content.picturePath,
        status: this.props.content.status,
      });
    }
  }

  render() {
    const { validationErrors } = this.state;

    return (
      <>
        <ModalTitle>
          {this.props.getTranslate(
            'additionalFeatures.featureCard.header.label'
          )}
        </ModalTitle>
        {this.state.isLoading && <Loader />}
        <ModalContent>
          <div className='add-additional-feature'>
            {fieldsConfig.map((field) => {
              return (
                <FormField
                  key={field.id}
                  field={field}
                  options={{
                    fieldsValues: {
                      ...this.state,
                    },
                    validationErrors,
                    onChange: this.onChange,
                    formClassName: 'add-additional-feature',
                  }}
                />
              );
            })}
          </div>
        </ModalContent>

        <ModalFooter>
          <Button
            status='outline'
            text={this.props.getTranslate(
              'additionalFeatures.featuresList.cancelEditing.button'
            )}
            onClick={this.onCancel}
          />
          <Button
            status='primary'
            text={this.props.getTranslate(
              'additionalFeatures.faturesList.save.button'
            )}
            onClick={this.addFeature}
          />
        </ModalFooter>
      </>
    );
  }

  onChange = async (fieldName: keyof Feature, value) => {
    this.setState((state) => {
      return {
        ...state,
        [fieldName]: value,
        validationErrors: {
          ...state.validationErrors,
          [fieldName]: '',
        },
      };
    });
  };

  addFeature = async () => {
    if (
      isNotAvailableForSupport(Messages.AdditionalFeature_Create) ||
      this.hasErrors()
    )
      return;

    this.setState({
      isLoading: true,
    });

    const request = this.props.content?.additionalFeatureId
      ? updateAdditionalFeature
      : createAdditionalFeature;

    const featureData = fieldsConfig.reduce<Feature>((data: Feature, item) => {
      if (item.id === 'iconPath' || item.id === 'picturePath') {
        return data;
      }
      if (item.id === 'languageCode') {
        return { ...data, language: this.state[item.id] };
      }
      return { ...data, [item.id]: this.state[item.id] };
    }, {} as Feature);

    if (this.props.content?.additionalFeatureId) {
      featureData.additionalFeatureId = this.props.content?.additionalFeatureId;
      featureData.sortIndex = this.props.content?.sortIndex;
    }

    try {
      await request(featureData);
    } catch (e) {
      console.error('Feature create error: ', e);
      this.setState({ isLoading: false });
    }
  };

  hasErrors = () => {
    const errors = fieldsConfig.reduce<Feature>((error, item) => {
      if (this.state[item.id]) return error;
      return {
        ...error,
        [item.id]: this.props.getTranslate('common.requiredField.error'),
      };
    }, {} as Feature);

    this.setState((state) => ({
      validationErrors: {
        ...state.validationErrors,
        ...errors,
      },
    }));

    return Object.values(errors).filter((item) => !!item).length !== 0;
  };

  onCancel = () => {
    this.props.dispatch(closeModal());
  };

  onEvent = async ({ data }) => {
    if (data.payload.validationErrors) {
      this.setState({ validationErrors: data.payload.validationErrors });
    } else {
      let iconPath: string = '',
        picturePath: string = '';
      try {
        if (this.state.picturePath) {
          picturePath = await loadFeaturePictures('picturePath', {
            additionalFeatureId: data.payload.additionalFeatureId,
            file: this.state.picturePath,
          });
        }
      } catch (e) {
        console.error('Upload picture error: ', e);
      }
      try {
        if (this.state.iconPath) {
          iconPath = await loadFeaturePictures('iconPath', {
            additionalFeatureId: data.payload.additionalFeatureId,
            file: this.state.iconPath,
          });
        }
      } catch (e) {
        console.error('Upload icon error: ', e);
      }

      if (this.props.content?.additionalFeatureId) {
        this.props.dispatch(
          updateStoredAdditionalFeature({
            ...data.payload,
            iconPath,
            picturePath,
          })
        );
      } else {
        this.props.dispatch(
          addAdditionalFeature({
            ...data.payload,
            iconPath,
            picturePath,
          })
        );
      }

      this.props.dispatch(closeModal());
    }
  };
}

export default connect()(addTranslation(AddAdditionalFeature));
