import React from 'react';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Link from 'components/ui/link';

interface Props extends IntlProps {
  addNewChart: () => void;
}

class AddGraphButton extends React.PureComponent<Props> {
  data;

  constructor(props) {
    super(props);
    this.data = [];
  }

  render() {
    const { getTranslate, addNewChart } = this.props;
    return (
      <div className='analytics__no-content'>
        <div className='analytics__no-content__inner'>
          {getTranslate('analytics.mainView.welcomeText.text')}{' '}
          <Link onClick={addNewChart} url='#'>
            {getTranslate('analytics.mainView.addingChart.text')}
          </Link>
        </div>
      </div>
    );
  }
}

export default addTranslation(AddGraphButton);
