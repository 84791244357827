import request from 'helpers/request';
import RpcService from 'helpers/RpcService';
import RpcTypes from 'constants/rpcTypes';

/**
 * Информация о текущем пользователе
 */
const getUser = () => {
  return new RpcService().request({
    messages: [{ type: RpcTypes.Profile_Get }],
  });
};

/**
 * Обновить пользователя
 * @param profile
 */
const updateUser = (profile) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Profile_Update,
        payload: { profile },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Обновить язык пользователя
 * @param interfaceLang
 */
const updateUserLanguage = (interfaceLang) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Profile_UpdateLanguage,
        payload: { interfaceLang },
      },
    ],
  });
};

/**
 * Обновить таймзону пользователя
 * @param timezone
 */
const updateUserTimezone = (timezone: string) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Profile_UpdateTimezone,
        payload: { timezone },
      },
    ],
  });
};

/**
 * Обновить пользователя
 */
const updateUserSudo = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.DevTest_CheckFeature,
        payload: {
          name: 'splitSecondFactorConfirmation',
        },
      },
    ],
    showValidateNotification: true,
  });
};

/**
 * Загрузка аватарки пользователя
 */
const uploadAvatar = (file) => {
  return request({
    method: 'POST',
    url: 'profile/avatar-upload',
    data: { file },
    headers: { 'Content-Type': 'multipart/form-data' },
  }).then(({ data }: any) => data.payload);
};

/**
 * Авторизация пользователя
 * Нужно присылать что-то в otp даже при выключенной двухфакторке
 */
const login = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_Login,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Подтверждение второго фактора
 */
const twoAuthConfirm = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_ConfirmSecondFactor,
        payload,
      },
    ],
    params: { authToken: payload.authToken },
    showValidateNotification: false,
  });
};

/**
 * Активация второго фактора отсылкой кода подтверждения из GA
 * Также используется для переотправки кода, если в params есть параметр regenerate
 */
const confirm2FA = (params) => {
  const { authToken, ...payload } = params;
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_ConfirmSecondFactorState,
        payload,
      },
    ],
    params: { authToken },
    showValidateNotification: false,
  });
};

/**
 * Измение пароля
 */
const changePassword = ({
  oldPassword,
  newPassword,
  newPassword2,
  twoFactorCode,
}) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_PasswordChange,
        payload: {
          oldPassword,
          newPassword,
          newPassword2,
          otp: twoFactorCode || '1',
        },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Сброс пароля
 */
const resetPassword = ({ email }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_PasswordReset,
        payload: { email },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Установка нового пароля после сброса
 */
const resetPasswordComplete = ({ password, passwordConfirm, token }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_PasswordResetComplete,
        payload: {
          newPassword: password,
          newPassword2: passwordConfirm,
          token,
        },
      },
    ],
    showValidateNotification: false,
  });
};

/**
 * Проверка и обновление токена
 */
const ping = (params: { authToken?: string; hasActivity?: boolean }) => {
  const { hasActivity, ...restParams } = params;
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_Ping,
        payload: { hasActivity },
      },
    ],
    params: restParams,
  });
};

/**
 * Проверка и обновление токена
 */
const checkToken = (token): Promise<{ isValid }> => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_PasswordResetCheckToken,
        payload: { token },
      },
    ],
  });
};

/**
 * Выйти из аккаунта
 */
const logout = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_Logout,
      },
    ],
  });
};

const logoutAll = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_LogoutFromAll,
      },
    ],
  });
};

/**
 * История активности
 */
const getActivity = (offset) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserActivity_CurrentUser,
        payload: {
          pagination: {
            offset,
          },
        },
      },
    ],
  });
};

const setConfig = ({ param, value }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserConfig_Set,
        payload: { param, value },
      },
    ],
  });
};

const getConfig = ({ param }) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserConfig_Get,
        payload: { param },
      },
    ],
  });
};

const setQuickActions = (value) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.UserConfig_Set,
        payload: { param: 'quickActions', value },
      },
    ],
  });
};

const loginAsSupport = (payload) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_LoginAsSupport,
        payload,
      },
    ],
    showValidateNotification: false,
  });
};

export const createJwtToken = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.Auth_Jwt,
      },
    ],
  });
};

/**
 * Test email letter
 */
const sendEmail = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.DevTest_mail,
        payload: {
          code: 'user_password_expired_soon',
          language: 'en',
          wl: 'PASTE YOUR WL',
          data: {
            placeholders: {
              USER_NAME: 'Chuck Norris',
              DAYS_LEFT: 1,
            },
          },
          preview: false,
        },
      },
    ],
  });
};

export const getRateLimitsInfo = () => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.RateLimit_Info,
      },
    ],
  });
};

export const actionAvailability = (
  subjectUserId: null | number,
  projectIds: number[],
  wlId: null | number,
  aclRoleNames: string[]
) => {
  return new RpcService().request({
    messages: [
      {
        type: RpcTypes.User_ActionAvailability,
        payload: {
          subjectUserId,
          projectIds,
          wlId,
          aclRoleNames,
        },
      },
    ],
  });
};

export default {
  getUser,
  updateUser,
  updateUserLanguage,
  updateUserTimezone,
  uploadAvatar,
  login,
  twoAuthConfirm,
  confirm2FA,
  logout,
  ping,
  changePassword,
  resetPassword,
  resetPasswordComplete,
  checkToken,
  getActivity,
  setConfig,
  getConfig,
  setQuickActions,
  updateUserSudo,
  loginAsSupport,
  createJwtToken,
  sendEmail,
  getRateLimitsInfo,
  logoutAll,
};
