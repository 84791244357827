import React from 'react';
import Tooltip from 'react-tooltip';
import classNames from 'classnames';
import { throttle } from 'lodash-es';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Icon from 'components/ui/icon';
import I18nHelpers from 'i18n/I18nHelpers';
import breakpoints from 'constants/breakpoints';
import './guideIcon.scss';

interface Props extends IntlProps {
  tooltip: string;
  className?: string;
}
interface State {
  windowWidth: number;
}

class GuideIcon extends React.PureComponent<Props, State> {
  private windowWidth = window.innerWidth;
  constructor(props) {
    super(props);
    this.state = {
      windowWidth: window.innerWidth,
    };

    this.handleResize = throttle(this.handleResize, 300);
  }

  componentDidMount() {
    window.addEventListener('resize', this.handleResize);
  }

  render() {
    const { tooltip, className } = this.props;
    const isMobile = this.state.windowWidth <= breakpoints.quickFilters;
    return (
      <div className='guide-icon'>
        <Icon
          name='im-Info-Square'
          onClick={(e) => {
            e.stopPropagation();
          }}
          size={16}
          isHtmlTooltip={true}
          className={classNames('guide-icon__icon', className)}
          tooltip={
            tooltip.includes('|') ? I18nHelpers.getHtmlString(tooltip) : tooltip
          }
          tooltipId='html'
          tooltipClassName='tooltip-info_blue guide-icon-tooltip'
          tooltipOffset={isMobile ? '{"bottom": 95}' : '{"left": -119 }'}
        />
      </div>
    );
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  handleResize = () => {
    if (
      (this.state.windowWidth <= breakpoints.quickFilters &&
        window.innerWidth > breakpoints.quickFilters) ||
      (this.state.windowWidth > breakpoints.quickFilters &&
        window.innerWidth < breakpoints.quickFilters)
    ) {
      this.setState({ windowWidth: window.innerWidth });
      setTimeout(() => Tooltip.rebuild(), 200);
    }
  };
}

export default addTranslation(GuideIcon);
