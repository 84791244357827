import React, { Component } from 'react';
import { connect } from 'react-redux';
import { isEqual, isEmpty } from 'lodash-es';
import { withRouter } from 'react-router-dom';
import { RootState, StoreProps } from 'store';
import { addPermissions, WithPermissions } from 'decorators/addPermissions';
import { WithRouterProps } from 'decorators/withRouter';
import { getData, saveDataState, changeDataForceUpdate } from 'actions/getData';
import { AnyObject } from 'types/Common';
import SearchFilters from 'types/SearchFilters';
import getFiltersValues from 'selectors/getFiltersValues';
import tableNames from 'constants/tableNames';
import Messages from 'constants/rpcTypes';
import CONFIG from 'config';
import path from 'helpers/path';
import {
  prepareDataForState,
  foundQuickFilters,
  getPathUrl,
} from 'helpers/paymentsPayoutsRequest';
import DynamicTable from 'components/ui/table/DynamicTable';

interface OwnProps {
  tableName: tableNames;
  isLoading?: boolean;
  quickFilter?: tableNames;
  apiRequstLimit?: number;
  resizableColumns?: boolean;
  customClass?: string;
  updateOnTimezoneChange?: boolean;
  minColumnsWidth?: { [key: string]: number };
  forbidResizeFor?: string[];
  headerRender?: React.ReactNode;
  rowRender?: (data: any, index: number) => React.ReactNode;
  apiRequest: (data?: any) => Promise<any>;
  getTableRef?: (ref: HTMLElement) => void;
}

interface ConnectedProps {
  user: AnyObject;
  itemsList: {
    isFetch: boolean;
    isFixed: false;
    items: any[];
    sort: {
      field: string;
      order: 'desc' | 'asc';
    };
    totalItems: number;
    forceUpdate?: boolean;
    isFetched?: boolean;
  };
  filters: AnyObject;
  quickFilters: AnyObject;
  searchFilters: {
    list: SearchFilters;
    selectedCount: number;
  };
}

type Props = OwnProps &
  ConnectedProps &
  WithPermissions &
  StoreProps &
  WithRouterProps;

interface State {
  offset: number;
}

class DataListContainer extends Component<Props, State> {
  fetchTimerId: NodeJS.Timeout | undefined = undefined;
  wasInitialFetch = false;

  constructor(props: Props) {
    super(props);

    this.state = {
      offset: 0,
    };
  }

  componentDidMount() {
    const { itemsList } = this.props;

    if (itemsList.items.length === 0) {
      this.fetchItems();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const {
      tableName: currentTableName,
      user: currentUser,
      itemsList: {
        isFetched,
        sort: currentSort,
        forceUpdate: currentForceUpdate,
      },
      filters: currentFilters,
      quickFilter: currentQuickFilter,
      quickFilters: currentQuickFilters,
      searchFilters: currentSearchFilters,
      updateOnTimezoneChange = true,
      dispatch,
    } = this.props;
    const {
      tableName: prevTableName,
      user: { timezone: prevTimeZone },
      itemsList: { sort: prevSort, forceUpdate: prevForceUpdate },
      filters: prevFilters,
      quickFilter: prevQuickFilter,
      quickFilters: prevQuickFilters,
      searchFilters: prevSearchFilters,
    } = prevProps;
    const { timezone: currentTimeZone } = currentUser;

    if (
      prevQuickFilter === currentQuickFilter &&
      !isEqual(prevQuickFilters, currentQuickFilters)
    ) {
      return this.fetchItems();
    }

    if (prevTableName !== currentTableName && !isFetched) {
      this.wasInitialFetch = false;
      return this.fetchItems();
    }

    if (!isEqual(prevSearchFilters, currentSearchFilters)) {
      return this.fetchItems();
    }

    if (!isEqual(prevFilters, currentFilters)) {
      return this.fetchItems();
    }

    if (!isEqual(prevSort, currentSort)) {
      return this.fetchItems();
    }

    if (
      currentUser &&
      prevTimeZone !== currentTimeZone &&
      updateOnTimezoneChange
    ) {
      return this.fetchItems();
    }

    if (!prevForceUpdate && currentForceUpdate) {
      this.fetchItems();
      return dispatch(changeDataForceUpdate(currentTableName, false));
    }
  }

  componentWillUnmount() {
    const { dispatch, tableName } = this.props;
    dispatch(saveDataState(tableName, this.state));
  }

  canViewItem = () => {
    const { tableName, isEnabled } = this.props;
    if (['payments', 'payouts'].includes(tableName)) {
      return isEnabled(Messages.Payments_Payment);
    }
    return true;
  };

  goToItem = (data: any) => {
    const { tableName, history } = this.props;

    if (this.canViewItem()) {
      const pathUrl = getPathUrl(tableName, data);

      if (pathUrl) {
        history.push(path(pathUrl));
      }
    }
  };

  fetchItems = (options?: any) => {
    const delay = 1000;
    const { filters, searchFilters, itemsList } = this.props;
    clearTimeout(this.fetchTimerId);

    return new Promise<void>((resolve) => {
      if (
        !itemsList.forceUpdate &&
        this.wasInitialFetch &&
        isEmpty(options) &&
        foundQuickFilters({ filters, searchFilters })
      ) {
        this.fetchTimerId = setTimeout(() => {
          this.fetchItemsStart(resolve, options);
        }, delay);
      } else {
        this.fetchItemsStart(resolve, options);
      }
    });
  };

  fetchItemsStart = (onResolve: () => void, options?: any) => {
    const {
      quickFilters,
      filters,
      searchFilters,
      itemsList: { sort },
      tableName,
      dispatch,
      apiRequest,
      apiRequstLimit = CONFIG.REQUEST_ITEMS_LIMIT,
    } = this.props;

    const baseRequest = {
      pagination: {
        limit: apiRequstLimit,
        offset: !isEmpty(options) && options.offset ? options.offset : 0,
      },
      sort,
      filter: {},
      saveFilter: false,
    };

    const request = prepareDataForState(
      { quickFilters, filters, searchFilters },
      baseRequest,
      { tableName }
    );

    let isUpload = false;
    let offset = 0;

    if (!isEmpty(options) && options.offset) {
      isUpload = true;
      offset = options.offset;
    }

    this.setState({
      offset,
    });

    dispatch(
      getData({
        apiRequest,
        request,
        onResolve,
        isUpload,
        name: tableName,
      })
    );
    this.wasInitialFetch = true;
  };

  render() {
    const {
      isLoading,
      itemsList,
      tableName,
      headerRender,
      customClass,
      forbidResizeFor,
      rowRender,
      getTableRef,
      minColumnsWidth = {},
      resizableColumns = true,
    } = this.props;
    const { offset } = this.state;
    const { isFetch, isFetched, items, totalItems } = itemsList;
    const thereAreItems = isFetched ? !!items.length : resizableColumns!;

    return (
      <DynamicTable
        isFetch={isFetch || isLoading}
        resizableColumns={resizableColumns! && thereAreItems}
        forbidResizeFor={forbidResizeFor}
        items={items}
        rowRender={rowRender}
        headerRender={headerRender}
        totalItems={totalItems}
        tableName={tableName}
        minColumnsWidth={minColumnsWidth}
        offset={offset}
        getTableRef={getTableRef}
        loadNextPage={this.fetchItems}
        clickToItem={this.goToItem}
        canViewItem={this.canViewItem()}
        customClass={customClass}
      />
    );
  }
}

const mapStateToProps = (
  state: RootState,
  props: OwnProps
): ConnectedProps => ({
  user: state.user,
  itemsList: state.data[props.tableName],
  quickFilters: state.quickFilters[props.quickFilter || props.tableName],
  searchFilters: state.searchFilters,
  filters: getFiltersValues(state, props.tableName),
});

export default connect(mapStateToProps)(
  withRouter(addPermissions(DataListContainer))
);
