import React, { useCallback, useContext } from 'react';
import { injectIntl, InjectedIntl } from 'react-intl';
import { AnyObject } from 'types/Common';

const IntlContext = React.createContext<InjectedIntl>({} as InjectedIntl);

export const InjectIntlContext = injectIntl(({ intl, children }) => {
  return <IntlContext.Provider value={intl}>{children}</IntlContext.Provider>;
});

interface GetTranslate {
  (id: string, formatValues?: AnyObject): string;
}

export const useTranslation = () => {
  const intl = useContext(IntlContext);

  const getTranslate = useCallback(
    (id: string, formatValues?: AnyObject) => {
      if (!id) {
        return '';
      }

      return intl.formatMessage({ id }, formatValues);
    },
    [intl]
  );

  return getTranslate as GetTranslate;
};
