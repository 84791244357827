import React, { ReactNode } from 'react';
import classNames from 'classnames';
import Tooltip from 'react-tooltip';
import ReactCountryFlag from 'react-country-flag';
import CleaveInput from 'cleave.js/react';
import 'cleave.js/dist/addons/cleave-phone.i18n';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import Input from 'components/ui/input';
import InputProps from '../input/InputProps';
import phoneDialCodes from 'constants/phoneDialCodes';
import CustomSelect from 'components/ui/customSelect';
import ConditionalWrapper from 'components/ui/conditionalWrapper';
import './phoneInput.scss';
import InfoIcon from 'components/ui/infoIcon';
import { isEqual } from 'lodash-es';

type Country = {
  label: string | ReactNode;
  value: string;
  dial_code: string;
  code: string;
};

type DialCountry = {
  name: string;
  dial_code: string;
  code: string;
  flag: string;
  preferred?: boolean;
  selected?: boolean;
} | null;

interface OwnProps {
  isSingleField?: boolean;
}

interface State {
  country: DialCountry;
  value: string;
  availableCountries: Country[];
}

type Props = OwnProps & InputProps & IntlProps;

const emptyCountry = {
  name: '',
  dial_code: '',
  code: '',
  flag: '',
};

class PhoneInput extends React.PureComponent<Props, State> {
  inputRef;
  countriesFound: Country[] = [];

  constructor(props) {
    super(props);
    const { value } = props;

    this.state = {
      ...this.initializeValues(value),
    };
    this.inputRef = React.createRef();
  }

  componentDidMount() {
    Tooltip.rebuild();
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>) {
    if (
      !this.props.isSingleField &&
      this.state.country?.dial_code !== prevState.country?.dial_code
    ) {
      this.props.onChange(null, {
        dialCountry: this.state.country || emptyCountry,
        value: this.state.value,
      });
    }

    if (
      (prevState.country?.code && !this.state.country?.code) ||
      (prevState.value && !this.state.value && !this.state.country?.code)
    ) {
      Tooltip.rebuild();
    }
  }

  render() {
    const { country, value, availableCountries } = this.state;
    const {
      selectedCountryCode,
      customClass,
      modern,
      error,
      isSingleField,
      getTranslate,
      ...props
    } = this.props;

    return (
      <div
        className={classNames('ui-phone-input', customClass, {
          'ui-phone-input_single-field': isSingleField,
          'ui-phone-input_empty': isSingleField && !country,
          'ui-phone-input_filled': isSingleField && !!country,
        })}>
        {isSingleField ? (
          this.renderSingleFieldFlag()
        ) : (
          <div className='ui-phone-input__inner'>
            <CustomSelect
              isSearchable
              modern
              isDisabled={this.props.disabled}
              id='dial-countries'
              options={availableCountries}
              label={getTranslate('input.phone.prefix.label')}
              placeholder={getTranslate('input.phone.prefix.placeholder')}
              value={country?.code ? this.getOption(country, true) : null}
              onChange={this.onChangeCountry}
              onOpen={this.clearValue}
              customClass='ui-phone-input__country ui-select_phone-country'
              error={error ? ' ' : undefined}
              filterOption={this.filterCountries}
            />
            <ConditionalWrapper
              condition={!this.state.country?.code && !this.state.value}
              wrap={(children) => (
                <span
                  data-tip={getTranslate('input.phone.codeRequired')}
                  className='ui-phone-input__field'>
                  {children}
                </span>
              )}>
              <Input
                {...props}
                inputContainerRef={this.inputRef}
                value={value}
                disabled={
                  props.disabled ||
                  (!this.state.country?.code && !this.state.value)
                }
                onChange={this.handleChange}
                cleaveOptions={{
                  numericOnly: true,
                }}
                customClass='ui-phone-input__field'
                error={error ? ' ' : undefined}
                modern
              />
            </ConditionalWrapper>
          </div>
        )}
        {!isSingleField && error && (
          <div className='ui-phone-input__error'>{error}</div>
        )}
        {country?.dial_code === '+44' && (
          <div className='ui-phone-input__note'>
            {getTranslate('input.phone.note')}
          </div>
        )}
      </div>
    );
  }

  renderSingleFieldFlag = () => {
    const {
      customClass,
      modern,
      isRequired,
      isSingleField,
      selectedCountryCode,
      getTranslate,
      ...rest
    } = this.props;
    const { country, availableCountries } = this.state;
    return (
      <div
        className={classNames('ui-input ui-input_prefix ui-input_modern', {
          'ui-input_error': this.props.error,
          'ui-input_disabled': this.props.disabled,
        })}>
        <div className='ui-input__wrapper ui-input__wrapper_modern'>
          <div className='ui-input__label'>
            {getTranslate(this.props.label)}
            {this.props.isRequired && (
              <span
                className='ui-input__required'
                data-tip={getTranslate('common.requiredField.tooltip')}>
                *
              </span>
            )}
          </div>
          <div className='ui-input__inner'>
            {availableCountries.length > 1 ? (
              <CustomSelect
                id='dial-countries'
                options={availableCountries}
                value={{
                  label: country?.code ? (
                    <ReactCountryFlag countryCode={country.code} svg />
                  ) : (
                    ''
                  ),
                  value: country?.code || '',
                }}
                onChange={this.onChangeFilter}
                customClass={classNames(
                  'ui-select_large ui-phone-input__flag ui-select_flag'
                )}
              />
            ) : (
              country?.code && (
                <ReactCountryFlag
                  className='ui-phone-input__flag'
                  countryCode={country?.code}
                  svg
                />
              )
            )}
            <CleaveInput
              {...rest}
              className='ui-input__field'
              value={this.state.value}
              onBlur={this.handleBlur}
              onChange={this.handleChangeSingleField}
              options={{
                initValue: this.state.value,
                phone: true,
                phoneRegionCode: this.state.country?.code,
              }}
            />
          </div>
          {this.props.tooltip && (
            <InfoIcon
              icon='im-Info'
              size={16}
              tooltip={getTranslate(this.props.tooltip)}
              customClass='ui-input__info'
              dataPlace='top'
            />
          )}
        </div>
        {this.props.error && (
          <span className='ui-input__error'>{this.props.error}</span>
        )}
      </div>
    );
  };

  getOption = (value, isValue = false) => {
    return {
      label: (
        <>
          <ReactCountryFlag
            countryCode={value.code}
            svg
            style={{
              fontSize: '20px',
              lineHeight: '20px',
            }}
          />
          {isValue ? (
            value.dial_code
          ) : (
            <>
              {value.name}{' '}
              <span className='ui-phone-input__code'>({value.dial_code})</span>
            </>
          )}
        </>
      ),
      value: `${value.name}${value.dial_code}`,
      dial_code: value.dial_code,
      code: value.code,
    };
  };

  onlyNumeric = (value) => (value ? value.replace(/[^0-9]/g, '') : '');

  handleChangeSingleField = (e) => {
    const { onChange } = this.props;
    e.persist();

    const value = e.target.value === '+' ? '' : e.target.value;
    const dialCountry = this.selectDialCountry(value);

    this.setState({
      value: dialCountry ? this.formatValue(value) : value,
      country: dialCountry,
      availableCountries: value ? this.getAvailableCountries(value) : [],
    });

    onChange(e, { dialCountry, value });
  };

  handleChange = (e) => {
    const { onChange } = this.props;
    e.persist();

    const value = e.target.value;

    this.setState({ value: this.onlyNumeric(value) });

    onChange(e, { dialCountry: this.state.country || emptyCountry, value });
  };

  handleBlur = () => {
    if (!this.onlyNumeric(this.state.value)?.length) {
      this.setState(this.initializeValues(''));
    }
  };

  onChangeFilter = ({ value: code }) => {
    const { value, country } = this.state;
    const chosenDialCountry = phoneDialCodes.find((c) => c.code === code);
    if (chosenDialCountry) {
      const { dial_code } = chosenDialCountry;
      let newValue;
      if (country?.dial_code) {
        newValue =
          value.length <= dial_code.length
            ? dial_code
            : this.formatValue(value).replace(country.dial_code, dial_code);
      } else {
        newValue = `${chosenDialCountry.dial_code}${value}`;
      }
      this.setState({
        country: { ...chosenDialCountry, selected: true },
        value: newValue,
        availableCountries: this.getAvailableCountries(newValue),
      });
    }
  };

  onChangeCountry = (filteredValue) => {
    if (!filteredValue) return;
    const chosenDialCountry = phoneDialCodes.find(
      (dCountry) => dCountry.code === filteredValue?.code
    );
    if (!chosenDialCountry) return;

    this.setState(
      {
        country: { ...chosenDialCountry, selected: true },
      },
      () => {
        if (!this.inputRef) return;
        this.inputRef.current.querySelector('input').focus();
      }
    );
  };

  initializeValues = (value) => {
    const formattedValue = this.formatValue(value);
    const initialCountry = this.selectDialCountry(
      value,
      this.props.selectedCountryCode
    );
    return {
      country: initialCountry,
      phoneNumber: initialCountry?.code
        ? value?.replace(initialCountry.dial_code, '')
        : '',
      keyCode: 0,
      value: this.props.isSingleField
        ? formattedValue || initialCountry?.dial_code || ''
        : this.onlyNumeric(this.getValue(formattedValue, initialCountry)),
      cursorPosition: value?.length || 0,
      availableCountries: this.getInitialCountries(value),
    };
  };

  getValue = (numberWithCode, dialCountry) => {
    return numberWithCode.startsWith(dialCountry?.dial_code)
      ? numberWithCode.slice(dialCountry?.dial_code.length)
      : numberWithCode;
  };

  formatValue = (value) => (value ? `+${this.onlyNumeric(value)}` : '');

  getAvailableCountries = (value) => {
    const { country } = this.state;
    const countryList =
      country?.code || value
        ? this.getMatchingCountries(value) || []
        : phoneDialCodes;

    return (
      countryList.map((c) => ({
        label: (
          <>
            <ReactCountryFlag countryCode={c.code} svg /> {c.dial_code}
          </>
        ),
        value: c.code,
        code: c.code,
        dial_code: c.dial_code,
      })) || [
        {
          label: '',
          value: '',
          code: '',
          dial_code: '',
        },
      ]
    );
  };

  getMatchingCountries = (value, selectedCountryCode?) => {
    const formattedValue = this.formatValue(value);
    return formattedValue
      ? phoneDialCodes
          .filter(
            (country) =>
              formattedValue.startsWith(country.dial_code) ||
              country.dial_code.startsWith(formattedValue) ||
              country.code === selectedCountryCode
          )
          .sort((a) => (a.preferred ? 1 : -1))
      : null;
  };

  selectDialCountry = (value, selectedCountryCode?) => {
    const { country: dialCountry } = this.state || {};

    const { isSingleField } = this.props;

    if (!isSingleField && (!selectedCountryCode || dialCountry?.code)) {
      return emptyCountry;
    }

    if (
      dialCountry?.code &&
      dialCountry.selected &&
      this.formatValue(value).startsWith(dialCountry.dial_code)
    ) {
      return dialCountry;
    }

    const countries = this.getMatchingCountries(value, selectedCountryCode);
    if (!countries) {
      return emptyCountry;
    }
    let country = countries[0];
    const selectedCountry =
      selectedCountryCode &&
      countries.length &&
      countries.find((c) => c.code === selectedCountryCode);

    if (selectedCountryCode && selectedCountry) {
      return selectedCountry;
    }
    const preferredCountry =
      countries.length && countries.find((c) => c.preferred);
    if (preferredCountry) {
      country = preferredCountry;
    }
    return country;
  };

  clearValue = () => {
    this.setState({ country: null });
  };

  filterCountries = (option, str: string) => {
    const data: Country = option.data;
    let isFound: boolean;

    if (str.toString().startsWith('+')) {
      isFound = data.dial_code.startsWith(str);
    } else if (typeof Number(str) === 'number' && !isNaN(Number(str))) {
      isFound = data.dial_code.slice(1).startsWith(str);
    } else {
      isFound = data.value.toLowerCase().startsWith(str.toLowerCase());
    }

    this.automaticCodeSelection(data, isFound);

    return isFound;
  };

  getInitialCountries = (value) => {
    if (!this.props.isSingleField) {
      return phoneDialCodes.map((country) => this.getOption(country));
    }

    const initialCountry = this.selectDialCountry(value);
    return [
      {
        label: initialCountry ? (
          <ReactCountryFlag countryCode={initialCountry?.code} svg />
        ) : (
          ''
        ),
        value: initialCountry?.code || '',
        dial_code: initialCountry?.dial_code || '',
        code: initialCountry?.code || '',
      },
    ];
  };

  automaticCodeSelection = (country: Country, isFound: boolean) => {
    const availableCountries = this.state.availableCountries;

    if (isEqual(country, this.state.availableCountries[0])) {
      this.countriesFound = [];
    }

    if (isFound) {
      this.countriesFound = [...this.countriesFound, country];
    }

    if (isEqual(country, availableCountries[availableCountries.length - 1])) {
      if (this.countriesFound.length === 1) {
        this.onChangeCountry(this.countriesFound[0]);
      }

      this.countriesFound = [];
    }
  };
}

export default addTranslation(PhoneInput);
