import React, { PureComponent, ReactNode, Fragment } from 'react';
import classNames from 'classnames';
import { debounce } from 'lodash-es';

import ApsIcon from 'components/ui/apsIcon';
import LabelStatus from 'components/ui/labelStatus';
import LabelPeriod from 'components/ui/labelPeriod';
import Counter from 'components/ui/counter';
import Icon from 'components/ui/icon';
import RoleItem from 'components/ui/roleItem';
import CopyToClipboard from 'components/ui/copyToClipboard';
import DeviceItem from 'components/ui/deviceItem';
import BrowserItem from 'components/ui/browserItem';
import FlagItem from 'components/ui/flagItem';
import Avatar from 'components/ui/avatar';
import Editable from '../../editable';
import Utils from 'helpers/Utils';
import Mapper from 'helpers/Mapper';
import { getTooltip } from 'helpers/getTooltip';
import ResizeBar from '../resizeBar/ResizeBar';
import { MIN_COLUMN_WIDTH, TABLE_HEADER_HEIGHT } from 'constants/ui';
import setNoChangeWordOrder from 'helpers/setNoChangeWordOrder';
import './column.scss';

interface Props {
  id: string;
  params?: any;
  content?: any;
  customClass?: string;
  modifier?: string;
  customTooltip?: string;
  columnWidths?: { [key: string]: number };
  minColumnsWidth?: { [key: string]: number };
  isTooltip?: boolean;
  data?: any;
  style?: any;
  header?: boolean;
  forbidResizeFor?: string[];
  resizableColumns?: boolean;
  setIsResizing?: (isResizing: boolean) => void;
  updateWidth?: ((id: string, width: number) => void) | undefined;
  tableHeight?: number;
}

interface State {
  tooltip: string | undefined;
  isTooltip: boolean;
}

const COLUMNS_WITHOUT_TOOLTIP = [
  'status',
  'scheduledAt',
  'updatedAt',
  'processingDate',
  'recurringEndDate',
  'creationDate',
  'updatedDate',
  'paymentStatus',
  'userStatus',
  'userRole',
  'periodType',
  'paymentMethodType',
  'userRole',
  'date',
];

class TableColumn extends PureComponent<Props, State> {
  private columnRef;
  static defaultProps = {
    customClass: '',
    isTooltip: true,
    style: {},
    resizableColumns: true,
  };

  constructor(props) {
    super(props);
    this.state = {
      tooltip: undefined,
      isTooltip: props.isTooltip,
    };

    this.checkTooltip = debounce(this.checkTooltip, 50, {
      leading: false,
      trailing: true,
    });
  }

  componentDidMount() {
    const { customTooltip } = this.props;
    const { isTooltip } = this.state;
    if (!customTooltip) {
      this.checkTooltip();
    }

    if (!customTooltip || isTooltip) {
      this.attachEvents();
    }
  }

  componentDidUpdate(prevProps: Readonly<Props>) {
    const { id, columnWidths } = this.props;
    const widthChanged =
      columnWidths && prevProps.columnWidths?.[id] !== columnWidths?.[id];

    if (this.props.content !== prevProps.content || widthChanged) {
      this.checkTooltip();
    }
  }

  render() {
    const {
      id,
      modifier,
      customClass,
      style,
      params,
      header,
      customTooltip,
      columnWidths,
      minColumnsWidth,
      resizableColumns,
      forbidResizeFor,
      updateWidth,
      tableHeight,
      setIsResizing,
    } = this.props;
    const { tooltip, isTooltip } = this.state;
    const content = this.prepareContentByParams();
    const isColumnResizable =
      resizableColumns && !forbidResizeFor?.includes(id);

    const resizeStyles =
      columnWidths && isColumnResizable
        ? {
            width: columnWidths[id],
            maxWidth: columnWidths[id],
          }
        : {};

    const dataTip =
      isTooltip && (customTooltip || tooltip) ? customTooltip || tooltip : '';

    return (
      <div
        ref={(el) => {
          this.columnRef = el;
        }}
        id={id}
        className={classNames(
          {
            'resizable-column': !!isColumnResizable,
            [`ui-table__column_${modifier}`]: !!modifier,
            'ui-table__header-item': header,
            'ui-table__column': !header,
            'ui-table__column_fixed': params?.fixed,
            [`ui-table__column_${params?.valueType}`]:
              params && params.valueType,
          },
          customClass
        )}
        style={resizeStyles ? { ...style, ...resizeStyles } : style}>
        <span
          className={classNames('ui-table__column_inner', {
            'ui-table__column-inner_error': params && params.error,
            'ui-table__column-inner_tip': !!customTooltip || !!tooltip,
          })}
          data-tip={dataTip}
          data-delay-hide='200'
          data-class='tooltip-custom'>
          {content}
        </span>
        {isColumnResizable && updateWidth && (
          <ResizeBar
            setIsResizing={setIsResizing}
            width={columnWidths && columnWidths[id]}
            minWidth={
              (minColumnsWidth && minColumnsWidth[id]) || MIN_COLUMN_WIDTH
            }
            updateWidth={updateWidth}
            id={id}
            height={tableHeight || TABLE_HEADER_HEIGHT}
          />
        )}
      </div>
    );
  }

  componentWillUnmount(): void {
    this.detachEvents();
  }

  attachEvents = () => {
    window.addEventListener('resize', this.checkTooltip);
  };

  detachEvents = () => {
    window.removeEventListener('resize', this.checkTooltip);
  };

  // eslint-disable-next-line complexity
  prepareContentByParams = () => {
    const { params, data } = this.props;
    let { content } = this.props;

    if (!params) {
      return content;
    }

    const { valueId, valueType, edit, isEditable } = this.props.params;
    switch (valueType) {
      case 'inverseText': {
        content = setNoChangeWordOrder(content);
        break;
      }
      case 'paymentMethod': {
        if (!data) break;
        content = (
          <ApsIcon
            paymentType={content}
            iconUrl={data.iconUrl}
            showTooltip={true}
          />
        );
        break;
      }
      case 'labelStatus': {
        if (typeof content !== 'string') break;
        if (valueId === 'fraud' && content.toLowerCase() === 'no') {
          content = 'No';
        } else {
          const mappedStatus = Mapper.getStatus(
            valueId === 'recurringStatus' ? `recurring-${content}` : content
          );
          const text = params.labelStatusText
            ? `${params.labelStatusText}.${content}`
            : content;
          content = <LabelStatus text={text} status={mappedStatus} />;
        }
        break;
      }
      case 'labelPeriod': {
        content =
          (content && content !== 'undefined' && (
            <LabelPeriod
              text={`${params.labelPeriodText}.${content}`}
              type={content}
            />
          )) ||
          '—';
        break;
      }
      case 'array': {
        content = (
          typeof content === 'string' ? content.split(',') : content
        ).map((item, idx) => <RoleItem text={item} key={idx} />);
        break;
      }
      case 'counter': {
        if (!data) break;
        const neutral = Utils.hasProp(params.config, 'neutral')
          ? data[params.config.neutral] || 0
          : undefined;
        content = (
          <span className={`ui-table__column-inner_${valueId}`}>
            <Counter
              success={data[params.config.success]}
              decline={data[params.config.fail]}
              neutral={neutral}
            />
          </span>
        );
        break;
      }
      case 'date': {
        content = (
          <span className='ui-table__column_date'>
            {typeof content === 'string'
              ? TableColumn.prepareDate(content)
              : content}
          </span>
        );
        break;
      }
      case 'copyText': {
        if (!content || content === '—') break;
        content = (
          <>
            <CopyToClipboard
              text={content}
              customClass='ui-table__column_copy'
            />
            <span className='ui-table__column-copy-text'>{content}</span>
          </>
        );
        break;
      }
      case 'deviceName': {
        if (!data) break;
        content = (
          <DeviceItem
            deviceCode={data[params.config.codeField]}
            deviceTitle={content}
          />
        );
        break;
      }
      case 'browserName': {
        if (!data) break;
        content = (
          <BrowserItem
            browserSrc={data[params.config.codeField]}
            browserTitle={content}
          />
        );
        break;
      }
      case 'countryCode': {
        if (!data) break;
        content = (
          <FlagItem
            flagName={content}
            ipAddress={data[params.config.additionalTextField]}
          />
        );
        break;
      }
      case 'boolean': {
        if (content === true) {
          content = (
            <span className={`payments-list__item-inner_${valueType}`}>
              <Icon size={12} name='im-Tick' />
            </span>
          );
        } else {
          content = (
            <span className={`payments-list__item-inner_${valueType}`}>—</span>
          );
        }
        break;
      }
      case 'avatar': {
        if (typeof content !== 'string') break;
        content = <Avatar user={{ avaUrl: content }} size='small' />;
      }
    }

    if (isEditable) {
      const {
        type,
        onChange,
        onTableInputBlur,
        onTableInputFocus,
        isError,
        canEdit,
        isForbiddenEdit,
        inputOptions,
      } = edit;
      content = (
        <Editable
          onChange={onChange}
          onBlur={(value) => {
            onTableInputBlur && onTableInputBlur(value);
          }}
          onFocus={(e) => {
            onTableInputFocus && onTableInputFocus(e);
          }}
          canEdit={canEdit}
          status={isError ? 'error' : ''}
          value={content}
          type={type}
          isForbiddenEdit={isForbiddenEdit}
          inputOptions={inputOptions}
        />
      );
    }

    return content;
  };

  checkTooltip = () => {
    const { params, header } = this.props;
    const { columnRef } = this;

    if (!columnRef || header) return;

    if (
      params &&
      (COLUMNS_WITHOUT_TOOLTIP.some((id) =>
        [params.id, params.valueId].includes(id)
      ) ||
        [params.valueType, params.type].includes('date'))
    ) {
      this.setState({ tooltip: undefined });
      return;
    }

    const tooltip = getTooltip(columnRef, '.ui-table__column_inner');
    this.setState({ tooltip });
  };

  static prepareDate(date: string): string | ReactNode {
    if (date.includes('\n')) {
      const [day, time] = date.split('\n');
      return (
        <Fragment>
          {day && (
            <span className='ui-table__column_date-text'>{day.trim()}</span>
          )}
          {time && (
            <span className='ui-table__column_date-text'>{time.trim()}</span>
          )}
        </Fragment>
      );
    }
    return date;
  }
}

export default TableColumn;
