import React from 'react';
import { connect } from 'react-redux';
import userApi from 'api/user';
import permissionApi from 'api/permissions';
import { addTranslation, IntlProps } from 'decorators/addTranslation';

import GetStartedPage from './GetStartedPage';
import { DocLinks } from 'types/Api';

import './getStarted.scss';
import { LegalEntity } from 'pages/businessDocuments/components/BusinessDocumentsList/types';
import { addListeners } from 'decorators/addListeners';
import Messages from 'constants/rpcTypes';
import { BoardingStage } from 'types/BoardingStage';

interface ConnectedProps {
  lang: string;
  docLinks: DocLinks;
  legalEntities: LegalEntity[];
}

interface State {
  isLoadingLimits: boolean;
  limits: { text: string; icon: string; current: number; max: number }[];
  boardingStage: BoardingStage;
}

type Props = ConnectedProps & IntlProps;

const limitDescription = {
  payments: {
    text: 'getStarted.limits.finOperations.text',
    icon: 'im-Finance',
  },
  rcs: { text: 'getStarted.limits.bwList.text', icon: 'im-Risk' },
  reports: { text: 'getStarted.limits.reports.text', icon: 'im-Reports' },
  team: { text: 'getStarted.limits.users.text', icon: 'im-Avatar' },
};

const FETCH_LIMITS_TIMEOUT = 120 * 1000;

@addListeners([Messages.LegalEntityOnProcessStatusUpdate])
class GetStartedPageContainer extends React.PureComponent<Props, State> {
  fetchLimitsTimeout;

  constructor(props) {
    super(props);
    this.state = {
      limits: [
        { text: '', icon: '', current: 0, max: 0 },
        { text: '', icon: '', current: 0, max: 0 },
        { text: '', icon: '', current: 0, max: 0 },
        { text: '', icon: '', current: 0, max: 0 },
      ],
      isLoadingLimits: false,
      boardingStage: this.getBoardingStage(props.legalEntities),
    };
  }

  componentDidMount() {
    try {
      this.fetchLimits();
      userApi.getUser();
    } catch (e) {
      console.error(e);
    }

    document
      .querySelector('.layout-app')
      ?.classList.add('layout-app_get-started');
  }

  private getBoardingStage = (legalEntities: LegalEntity[]) => {
    return legalEntities?.[0]?.['boarding_status'];
  };

  componentDidUpdate(prevProps) {
    if (
      this.getBoardingStage(prevProps.legalEntities) !==
      this.getBoardingStage(this.props.legalEntities)
    ) {
      this.setState({
        boardingStage: this.getBoardingStage(this.props.legalEntities),
      });
    }
  }

  get displayOnboardingInformer() {
    return [
      'onboarding_draft',
      'onboarding_fill',
      'onboarding_review',
    ].includes(this.state.boardingStage);
  }

  render() {
    const { lang, docLinks, getTranslate } = this.props;
    const { limits, isLoadingLimits } = this.state;
    return (
      <GetStartedPage
        displayOnboardingInformer={this.displayOnboardingInformer}
        boardingStage={this.state.boardingStage}
        toTechDocs={this.toTechDocs}
        docLinks={docLinks?.[lang].links.documentation}
        getTranslate={getTranslate}
        limits={limits}
        isLoadingLimits={isLoadingLimits}
      />
    );
  }

  componentWillUnmount() {
    document
      .querySelector('.layout-app_get-started')
      ?.classList.remove('layout-app_get-started');

    this.fetchLimitsTimeout && clearTimeout(this.fetchLimitsTimeout);
  }

  toTechDocs = (url: string) => window.open(url, '_blank');

  fetchLimits = async () => {
    try {
      this.setState({ isLoadingLimits: true });
      const limits = await userApi.getRateLimitsInfo();
      const limitsInOrder = {
        payments: limits.payments,
        rcs: limits.rcs,
        team: limits.team,
        reports: limits.reports,
      };
      this.setState({
        limits: Object.keys(limitsInOrder).map((key) => ({
          text: limitDescription[key].text,
          icon: limitDescription[key].icon,
          current: limits[key].rate_current.limit,
          max: limits[key].rate_max.limit,
        })),
      });
    } catch (e) {
      console.error(e);
    } finally {
      this.setState({ isLoadingLimits: false });
      this.fetchLimitsTimeout = setTimeout(
        this.fetchLimits,
        FETCH_LIMITS_TIMEOUT
      );
    }
  };

  async onEvent(event) {
    clearTimeout(this.fetchLimitsTimeout);
    this.fetchLimits();

    if (event.data.payload.result === 'ok') {
      permissionApi.getPermissions();
      this.setState({ boardingStage: event.data.payload.onProcessStatus });
    }
  }
}

const mapStateToProps = (state): ConnectedProps => ({
  lang: state.user.interfaceLang || 'en',
  docLinks: state.demoStage.docLinks,
  legalEntities: state.user?.activeLegalEntities,
});

export default connect(mapStateToProps)(
  addTranslation(GetStartedPageContainer)
);
