import React, { lazy } from 'react';
import { Redirect } from 'react-router-dom';
import getPath from 'helpers/path';
import menuConfiguration from 'constants/menuConfiguration';
import urlsMap from 'constants/urlsMap';
import { Route } from './routeTypes';

import Root from 'pages/root';
import Payments from 'pages/payments';
import LcManage from 'pages/lcmanage';
import LcManageUpload from 'pages/lcmanage/upload';
import Projects from 'pages/projects';
import Invoices from 'pages/invoices';
import Recurring from 'pages/recurring';
import Risk from 'pages/risks/RisksContainer';
import References from 'pages/references';
import Analytics from 'pages/analytics';
import Team from 'pages/team';
import UserBuilder from 'components/userBuilder';
import { BusinessDocuments } from 'pages/businessDocuments';
import Icons from 'pages/icons';
import UserManage from 'pages/admin';
import Help from 'pages/help';
import Reconciliation from 'pages/reconciliation';
import AdminTools from 'pages/adminTools';
import GetStarted from 'pages/getStarted';
import Remittance from 'pages/remittance';
import AdditionalFeature from 'pages/getStarted/overview/components/additionalFeature';
import FinancialData from 'pages/financialData';
import Messages from 'constants/rpcTypes';
import { AccountingStatementRequestsContainer } from 'pages/accountingStatementRequests';

const Payouts = lazy(() => import('pages/payouts'));
const Reports = lazy(() => import('pages/reports'));
const Finance = lazy(() => import('pages/finance'));
const Chargebacks = lazy(() => import('pages/chargebacks'));
const Profile = lazy(() => import('pages/profile'));

export const privateRoutesConfig: Route[] = [
  {
    path: getPath('/'),
    component: Root,
    exact: true,
    params: {
      permissions: [
        Messages.Payments_List,
        Messages.Maf_Get,
        Messages.DemoStage_Project,
        Messages.Profile_Get,
      ],
    },
  },
  {
    path: getPath('/profile'),
    component: Profile,
    params: {
      permissions: [Messages.Profile_Get],
    },
  },
  {
    path: getPath('/payments/:id?'),
    component: Payments,
  },
  {
    path: getPath(`/${urlsMap.payouts}/request/:formId`),
    component: Payouts,
  },
  {
    path: getPath(`/${urlsMap.payouts}/:id?`),
    component: Payouts,
  },
  {
    path: getPath('/lcmanage/upload'),
    component: LcManageUpload,
  },
  {
    path: getPath('/lcmanage'),
    component: LcManage,
  },
  {
    path: getPath('/maf/:id?'),
    component: BusinessDocuments,
  },
  {
    path: getPath('/reports/create'),
    component: Reports,
    params: {
      isTemplatesBuilder: true,
    },
  },
  {
    path: getPath('/reports/:id'),
    component: Reports,
    params: {
      isTemplatesBuilder: true,
    },
  },
  {
    path: getPath('/reports/templates'),
    component: Reports,
    params: {
      isTemplatesList: true,
    },
  },
  {
    path: getPath('/reports'),
    component: Reports,
  },
  {
    path: getPath('/get-started/features'),
    component: AdditionalFeature,
  },
  {
    path: getPath('/get-started'),
    component: GetStarted,
  },

  {
    path: getPath('/analytics/:slug?'),
    component: Analytics,
  },
  {
    path: getPath('/analytics'),
    component: Analytics,
  },
  {
    path: getPath('/admin'),
    exact: true,
    component: UserManage,
    params: {
      isAdmin: true,
    },
  },
  {
    path: getPath('/admin/create'),
    component: UserBuilder,
    params: {
      isAdmin: true,
    },
  },
  {
    path: getPath('/admin/edit/:id'),
    component: UserBuilder,
    params: {
      isAdmin: true,
    },
  },
  {
    path: getPath('/team/create'),
    component: UserBuilder,
  },
  {
    path: getPath('/team/edit/:id'),
    component: UserBuilder,
  },
  {
    path: getPath('/team'),
    component: Team,
  },
  {
    path: getPath('/finance/:id/:pageId/:entityId'),
    component: Finance,
  },
  {
    path: getPath('/finance/:id/:pageId'),
    component: Finance,
  },
  {
    path: getPath('/finance/:id'),
    component: Finance,
  },
  {
    path: getPath('/finance'),
    component: Finance,
  },
  {
    path: getPath('/recurring-payments/:id?'),
    component: Recurring,
  },
  {
    path: getPath('/projects/:id?'),
    component: Projects,
  },
  {
    path: getPath('/invoices/:formId'),
    component: Invoices,
    params: {
      isCreate: true,
    },
  },
  {
    path: getPath('/invoices'),
    component: Invoices,
  },
  {
    path: getPath('/references'),
    component: References,
  },
  {
    path: getPath('/risks/:id?'),
    component: Risk,
  },
  {
    path: getPath('/chargebacks/:id?'),
    component: Chargebacks,
  },
  {
    path: getPath('/icons'),
    component: Icons,
  },
  {
    path: getPath('/admin-tools'),
    component: AdminTools,
  },
  {
    path: getPath('/admin-fin-data'),
    component: FinancialData,
  },
  {
    path: getPath('/help/:slug?'),
    component: Help,
    key: 'help',
    params: {
      permissions: [Messages.Article_Menu],
    },
  },
  {
    path: getPath('/accounting-statement-request'),
    component: AccountingStatementRequestsContainer,
    key: 'accounting-statement-request',
  },
  {
    path: getPath('/reconciliation'),
    component: Reconciliation,
    key: 'reconciliation',
  },
  {
    path: getPath(`/${urlsMap.remittance}/:id?/:formId?`),
    component: Remittance,
    key: 'remittance',
  },

  {
    component: () => <Redirect to={getPath('')} />,
  },
];

const setPermissions = (route: Route) => {
  if (route.params?.permissions) {
    return route.params;
  }

  const routePath = route.path?.split('/')[1];
  const paramsItem = menuConfiguration.find((item) => {
    if (item.customUrl) {
      return item.customUrl === routePath;
    }
    return item.key === routePath;
  });

  return {
    ...route.params,
    permissions: paramsItem?.permissions,
  };
};

const setSettings = (): Route[] => {
  return privateRoutesConfig.map((route): Route => {
    return {
      ...route,
      params: { ...setPermissions(route) },
    };
  });
};

const privateRoutes: Route[] = setSettings();

export default privateRoutes;
