import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { Prompt, withRouter } from 'react-router-dom';
import { isEqual, pickBy } from 'lodash-es';

import {
  createRemittance,
  getRecipientAccount,
  updateRemittance,
} from 'api/remittance';
import { addEntityToSaved } from 'actions/savedEntities';
import { confirmLeavePage } from 'actions/app';
import { openModal } from 'actions/modal';
import { addListeners } from 'decorators/addListeners';
import { addTranslation, IntlProps } from 'decorators/addTranslation';
import { WithRouterProps } from 'decorators/withRouter';
import RemittanceBuilder from './RemittanceAccountBuilder';
import Loader from 'components/ui/loader';
import initialFields from '../initialFields';
import { fieldsConfig } from './config';
import savedEntities from 'constants/savedEntities';
import Messages from 'constants/rpcTypes';
import { createRoutes } from '../routesMapper';
import Utils from 'helpers/Utils';
import isNotAvailableForSupport from 'helpers/isNotAvailableForSupport';
import checkFilters from 'helpers/checkFilters';
import { isCustomId } from 'pages/remittance/helpers';
import { AnyObject } from 'types/Common';
import { DictionaryCustomSelect } from 'types/FilterValue';
import { StoreProps } from 'store';
import tableNames from 'constants/tableNames';
import './remittanceBuilder.scss';

interface OwnProps {
  id?: string;
}

interface ConnectedProps {
  isConfirmLeavePage: boolean;
  filtersValues: {
    projectIdAvailable: DictionaryCustomSelect;
    transferCurrencyAvailable: DictionaryCustomSelect;
    countryAvailable: DictionaryCustomSelect;
    allCountries: DictionaryCustomSelect;
  };
  storedSavedEntities: any;
  modal: any;
}

type Props = OwnProps &
  ConnectedProps &
  StoreProps &
  IntlProps &
  WithRouterProps;

interface State {
  canSave: boolean;
  currentStep: number;
  canApprove: boolean;
  isLoading: boolean;
  isSubmitting: boolean;
  isConfirm: boolean;
  isFetched: boolean;
  fields: any;
  validationErrors: AnyObject;
}

@addListeners([
  Messages.Remittance_CreateRecipientAccount,
  Messages.Remittance_UpdateRecipientAccount,
  Messages.Remittance_GetRecipientAccount,
])
class RemittanceAccountBuilderContainer extends PureComponent<Props, State> {
  private oldFields;
  private pathToGo;
  private isCreate;
  constructor(props: Props) {
    super(props);
    this.state = {
      currentStep: 0,
      fields: { ...initialFields },
      validationErrors: {},
      canSave: false,
      canApprove: false,
      isConfirm: false,
      isSubmitting: false,
      isLoading: Boolean(
        props.id &&
          !props.location.pathname.includes(
            createRoutes[tableNames.remittanceRecipientAccounts]
          )
      ),
      isFetched: false,
    };
    this.isCreate =
      props.id &&
      props.location.pathname.includes(
        createRoutes[tableNames.remittanceRecipientAccounts]
      );
  }

  async componentDidMount() {
    await checkFilters('remittanceRecipientAccountsBuilder');

    await this.setFieldsToState();

    if (this.props.id && !this.isCreate) {
      await this.getRemittanceRecipient();
    }

    this.setSavedEntities();
  }

  async componentDidUpdate(
    prevProps: Readonly<Props>,
    prevState: Readonly<State>
  ) {
    if (prevProps.id !== this.props.id) {
      this.isCreate =
        this.props.id &&
        this.props.location.pathname.includes(
          createRoutes[tableNames.remittanceRecipientAccounts]
        );

      if (!this.isCreate) {
        if (this.props.id && !this.props.location.search) {
          await this.getRemittanceRecipient();
        } else {
          await this.setFieldsToState();
        }
      } else {
        await this.setFieldsToState();
      }
    }

    if (
      (!prevProps.storedSavedEntities?.items &&
        this.props.storedSavedEntities?.items) ||
      (!prevState.isFetched && this.state.isFetched)
    ) {
      this.setSavedEntities();
    }

    if (
      prevProps.isConfirmLeavePage &&
      !this.props.isConfirmLeavePage &&
      this.props.modal.modalId
    ) {
      this.handlePageNavigation(this.pathToGo);
    }
  }

  render() {
    const {
      fields,
      validationErrors,
      canSave,
      canApprove,
      isLoading,
      isSubmitting,
      currentStep,
    } = this.state;
    const {
      filtersValues: {
        projectIdAvailable,
        transferCurrencyAvailable,
        countryAvailable,
        allCountries,
      },
    } = this.props;
    const { id, isConfirmLeavePage } = this.props;

    if (isLoading) {
      return <Loader />;
    }

    return (
      <>
        <RemittanceBuilder
          fields={fields}
          dictionaries={{
            projectId: projectIdAvailable,
            transferCurrency: transferCurrencyAvailable,
            companyLegalCountry: countryAvailable,
            beneficiaryBankCountry: countryAvailable,
            allCountries: allCountries,
          }}
          id={id}
          isSubmitting={isSubmitting}
          canSave={canSave}
          canApprove={canApprove}
          onChangeField={this.onChange}
          save={() => this.save()}
          approve={this.approve}
          validationErrors={validationErrors}
          setCurrentStep={this.setCurrentStep}
          currentStep={currentStep}
        />
        <Prompt when={isConfirmLeavePage} message={this.handleNavigation} />
      </>
    );
  }

  setCurrentStep = (stepIndex) => {
    this.setState({ currentStep: stepIndex });
  };

  handleNavigation = (location) => {
    const { isConfirm } = this.state;
    this.pathToGo = location.pathname;
    if (location.pathname === '/login') return true;

    if (isConfirm) {
      this.showConfirmModal();
      return false;
    }
    return true;
  };

  handlePageNavigation = (locate) => {
    const { history } = this.props;

    history.push(locate);
  };

  showConfirmModal() {
    const { dispatch } = this.props;

    dispatch(
      openModal({
        modalId: 'Confirm',
        content: {
          title: 'projects.urls.modal.urlChangeDiscard.header',
          text: 'projects.urls.modal.urlChangeDiscard.label',
        },
        callback: (answer) => {
          this.preventLeavePage({ isConfirm: !answer });
        },
      })
    );
  }

  preventLeavePage = (answer) => {
    const { dispatch } = this.props;

    dispatch(
      confirmLeavePage({
        isConfirm: answer
          ? answer.isConfirm
          : !isEqual(this.oldFields, this.state.fields),
      })
    );
  };

  setSavedEntities = () => {
    const { id, dispatch, storedSavedEntities } = this.props;
    const { fields } = this.state;

    if (
      !storedSavedEntities?.isFetch ||
      storedSavedEntities.items.some((entity) => {
        return String(entity.id) === id;
      })
    )
      return;

    let savedEntityParams;

    if (
      !id?.includes(createRoutes[tableNames.remittanceRecipientAccounts]) &&
      fields
    ) {
      savedEntityParams = {
        entityKey: savedEntities.remittance,
        id: `${id}`,
        hiddenParams: {
          localeKey: this.getCardName(),
        },
      };
    } else {
      if (
        storedSavedEntities.items.some(
          (entity) =>
            entity.id === createRoutes[tableNames.remittanceRecipientAccounts]
        )
      ) {
        return;
      }
      savedEntityParams = {
        entityKey: savedEntities.remittance,
        id: createRoutes[tableNames.remittanceRecipientAccounts],
        hiddenParams: {
          localeKey: 'remittance.newRecipientAcc.header',
          isCreate: true,
        },
      };
    }

    dispatch(addEntityToSaved(savedEntityParams));
  };

  setFieldsToState = async () => {
    const { filtersValues } = this.props;
    if (!filtersValues) return;

    this.setState({
      fields: {
        ...initialFields,
        transferCurrency: filtersValues.transferCurrencyAvailable?.list?.find(
          (option) => option.value === 'EUR'
        )?.value,
      },
    });
  };

  onChange = (name, value) => {
    this.setState(
      (state) => ({
        fields: {
          ...state.fields,
          [name]: value,
        },
        validationErrors: { ...state.validationErrors, [name]: '' },
      }),
      this.checkRequired
    );
  };

  checkRequired = () => {
    const { fields, validationErrors } = this.state;

    const isCreated = fields.status === 'created';
    const fieldItems = Object.values(fieldsConfig).flat();

    const canSave = !isCreated && fieldItems.some((item) => fields[item.id]);
    const canApprove =
      !isCreated &&
      !Object.values(validationErrors).some((i) => i) &&
      fieldItems
        .filter((item) => item.isRequired)
        .every((item) => fields[item.id]);

    this.setState(
      (state) => ({
        canSave: canSave && !isEqual(this.oldFields, state.fields),
        canApprove,
        isConfirm: !isEqual(this.oldFields, state.fields),
      }),
      () => this.preventLeavePage(false)
    );
  };

  save = async (status?) => {
    const { canSave, fields } = this.state;
    const isCreated = status === 'created';
    if (!isCreated && !canSave) return;
    if (isNotAvailableForSupport(Messages.Remittance_CreateRecipientAccount))
      return;
    try {
      this.setState({
        isSubmitting: true,
      });
      const payload = {
        ...fields,
        monthlyMinAmount: +Utils.getNumberWithoutSpace(fields.monthlyMinAmount),
        transferMaxAmount: +Utils.getNumberWithoutSpace(
          fields.transferMaxAmount
        ),
        paymentNumber: +fields.paymentNumber,
        status,
      };

      if (this.isCreate) {
        await createRemittance(pickBy(payload));
      } else {
        await updateRemittance(pickBy(payload));
      }
    } finally {
      this.setState({
        isSubmitting: false,
      });
    }
  };

  approve = async () => {
    await this.save('created');
  };

  saveAndApprove = async () => {
    if (this.state.canSave) {
      await this.save();
    }

    await this.approve();
  };

  getRemittanceRecipient = async () => {
    try {
      this.setState({
        isLoading: true,
      });
      await getRecipientAccount(this.props.id);
    } finally {
      this.setState({
        isLoading: false,
        isFetched: true,
      });
    }
  };

  getCardName() {
    const { id, getTranslate } = this.props;
    const { fields } = this.state;
    if (isCustomId(id)) {
      return `${
        fields.title || getTranslate('remittance.editDraftRecipientAcc.header')
      } #${id}`;
    }
    return getTranslate('remittance.addRecipientAcc.header');
  }

  onEvent = ({ name, data }) => {
    if (data.payload.validationErrors) {
      this.setState({ validationErrors: data.payload.validationErrors });
    } else {
      this.oldFields = data.payload;
      this.setState(
        {
          fields: {
            ...data.payload,
          },
          validationErrors: {},
        },
        this.checkRequired
      );

      if (
        name === Messages.Remittance_CreateRecipientAccount ||
        name === Messages.Remittance_UpdateRecipientAccount
      ) {
        if (
          data.payload.status !== 'created' &&
          data.payload.status !== 'active'
        ) {
          this.props.dispatch(
            openModal({
              modalId: 'Notification',
              content: {
                title: 'remittance.addRecipientAcc.modal.saveDraft.text',
              },
              callback: () => {
                this.props.history.push(`/remittance/recipient-accounts`);
              },
            })
          );
        } else {
          this.props.history.push(`/remittance/recipient-accounts`);
        }
      }
    }
  };
}

const mapStateToProps = (state): ConnectedProps => {
  const getItems = function (list) {
    if (!list) return [];
    return list.map((item) => {
      return {
        value: item.id || item.projectId || '',
        label: item.text,
      };
    });
  };

  return {
    isConfirmLeavePage: state.app.isConfirm,
    filtersValues: {
      projectIdAvailable: {
        ...state.filtersValues.remittanceProject,
        list: getItems(state.filtersValues.remittanceProject?.list),
      },
      transferCurrencyAvailable: {
        ...state.filtersValues.remittanceTransferCurrency,
        list: getItems(state.filtersValues.remittanceTransferCurrency?.list),
      },
      countryAvailable: {
        ...state.filtersValues.remittanceCountry,
        list: getItems(state.filtersValues.remittanceCountry?.list),
      },
      allCountries: {
        ...state.filtersValues.country,
        list: getItems(state.filtersValues.country?.list),
      },
    },
    storedSavedEntities: state.savedEntities[savedEntities.remittance],
    modal: state.modal,
  };
};

export default withRouter(
  connect(mapStateToProps)(addTranslation(RemittanceAccountBuilderContainer))
);
