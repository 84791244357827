import React, { PureComponent } from 'react';
import ModalContent from '../components/ModalContent';
import { closeModal, editOptions } from 'actions/modal';
import api from 'api/projects';
import { StoreProps } from 'store';
import { connect } from 'react-redux';

interface Props {
  content: {
    paymentPageDesignerVersionFiveUrl: string;
    projectId: string;
    onModalClose: () => void;
  };
}

enum EDITOR_COMMANDS {
  COMMAND_SAVE_STYLE_REQUEST = 'save-style-request',
  COMMAND_SAVE_STYLE_RESPONSE = 'save-style-response',
  COMMAND_CLOSE_DESIGNER = 'close-designer',
}

class PaymentPageDesigner extends PureComponent<Props & StoreProps> {
  private iframeRef: React.RefObject<HTMLIFrameElement> = React.createRef();

  componentDidMount() {
    const { dispatch } = this.props;

    dispatch(
      editOptions({
        needCloseButton: false,
        onClickAwayClose: false,
      })
    );

    window.addEventListener('message', this.handleMessage);
  }

  componentWillUnmount() {
    window.removeEventListener('message', this.handleMessage);
  }

  render() {
    const {
      content: { paymentPageDesignerVersionFiveUrl },
    } = this.props;

    return (
      <ModalContent>
        <iframe
          ref={this.iframeRef}
          src={paymentPageDesignerVersionFiveUrl}
          name='constructor'
          title='Payment Page Designer'
          style={{ display: 'block', height: '100%', width: '100%' }}
        />
      </ModalContent>
    );
  }

  handleMessage = async (event: MessageEvent) => {
    const { message } = event.data;

    const mapMessageToAction: { [key: string]: () => void } = {
      [EDITOR_COMMANDS.COMMAND_CLOSE_DESIGNER]: this.closeModal,
      [EDITOR_COMMANDS.COMMAND_SAVE_STYLE_REQUEST]: async () => {
        const { message, ...payload } = event.data;
        try {
          await this.saveStyles(
            JSON.stringify({
              ...payload,
              styles: JSON.parse(event.data.styles),
            })
          );
          this.sendMessageToEditor(
            EDITOR_COMMANDS.COMMAND_SAVE_STYLE_RESPONSE,
            {
              ...payload,
              success: true,
            }
          );
        } catch (e) {
          this.sendMessageToEditor(
            EDITOR_COMMANDS.COMMAND_SAVE_STYLE_RESPONSE,
            {
              ...payload,
              success: false,
              error: 'Error while saving styles',
            }
          );
        }
      },
    };

    // run action by message if action exists
    mapMessageToAction[message]?.();
  };

  sendMessageToEditor = (message: EDITOR_COMMANDS, data: object) => {
    if ('message' in data) {
      throw new Error('message is reserved property');
    }

    this.iframeRef.current?.contentWindow?.postMessage(
      {
        message,
        ...data,
      },
      '*'
    );
  };

  closeModal = () => {
    const {
      dispatch,
      content: { onModalClose },
    } = this.props;
    dispatch(closeModal());
    onModalClose();
  };

  saveStyles = async (styles: any) => {
    await api.saveProjectEditorStyles({
      projectId: this.props.content.projectId,
      styles: styles,
    });
  };
}

export default connect()(PaymentPageDesigner);
